import { Critere } from "../models/Critere";

// [
//     {
//       code : "R1.1",
//       traduction : "Compte de résultat - Intérêts et produits assimilés"
//     },
//     {
//       code : "R2.1",
//       traduction : "Compte de résultat - Intérêts et charges assimilées"
//     },
//     {
//       code : "R3.1",
//       traduction : "Compte de résultat - Résultat de l’exercice"
//     },
//     {
//       code : "A1.1",
//       traduction : "ACTIF - Créances sur les Ets de crédit"
//     },
//     {
//       code : "A2.1",
//       traduction: "ACTIF - Créances sur la clientèle"
//     },
//     {
//       code : "A3.1",
//       traduction: "TOTAL ACTIF"
//     },
//     {
//       code : "P1.1",
//       traduction : "PASSIF - Dettes envers les Ets de crédit"
//     },
//     {
//       code : "P2.1",
//       traduction : "PASSIF - Comptes créditeurs à la clientèle"
//     },
//     {
//       code : "P3.1",
//       traduction : "Capital souscrit"
//     },
//     {
//       code : "P4.1",
//       traduction : "PASSIF - Primes d’émission"
//     },
//     {
//       code : "P5.1",
//       traduction : "PASSIF - Réserves"
//     },
//     {
//       code : "P6.1",
//       traduction : "PASSIF - Ecarts de réévaluation"
//     },
//     {
//       code : "P7.1",
//       traduction : "PASSIF - Report à nouveau"
//     },
//     {
//       code : "P8.1",
//       traduction : "PASSIF - Résultat de l’exercice"
//     },
//     {
//       code : "P9.1",
//       traduction : "TOTAL PASSIF"
//     },
//   ];
  
export const listCriteresBanque: Critere[] = [
  {
      "code": "siren",
      "traduction": "siren"
  },
  {
      "code": "date_cloture_exercice",
      "traduction": "date_cloture_exercice"
  },
  {
      "code": "code_greffe",
      "traduction": "code_greffe"
  },
  {
      "code": "num_depot",
      "traduction": "num_depot"
  },
  {
      "code": "num_gestion",
      "traduction": "num_gestion"
  },
  {
      "code": "code_activite",
      "traduction": "code_activite"
  },
  {
      "code": "date_cloture_exercice_n_precedent",
      "traduction": "date_cloture_exercice_n_precedent"
  },
  {
      "code": "duree_exercice_n",
      "traduction": "duree_exercice_n"
  },
  {
      "code": "duree_exercice_n_precedent",
      "traduction": "duree_exercice_n_precedent"
  },
  {
      "code": "date_depot",
      "traduction": "date_depot"
  },
  {
      "code": "code_motif",
      "traduction": "code_motif"
  },
  {
      "code": "code_type_bilan",
      "traduction": "code_type_bilan"
  },
  {
      "code": "code_devise",
      "traduction": "code_devise"
  },
  {
      "code": "code_origine_devise",
      "traduction": "code_origine_devise"
  },
  {
      "code": "code_confidentialite",
      "traduction": "code_confidentialite"
  },
  {
      "code": "denomination",
      "traduction": "denomination"
  },
  {
      "code": "adresse",
      "traduction": "adresse"
  },
  {
      "code": "COMPTES ANNUELS.1",
      "traduction": "Montant année N"
  },
  {
      "code": "COMPTES ANNUELS.2",
      "traduction": "Montant année N-1"
  },
  {
      "code": "A1.1",
      "traduction": "ACTIF - Créances sur les Ets de crédit Montant année N"
  },
  {
      "code": "A1.2",
      "traduction": "ACTIF - Créances sur les Ets de crédit Montant année N-1"
  },
  {
      "code": "A2.1",
      "traduction": "ACTIF - Créances sur la clientèle Montant année N"
  },
  {
      "code": "A2.2",
      "traduction": "ACTIF - Créances sur la clientèle Montant année N-1"
  },
  {
      "code": "A3.1",
      "traduction": "TOTAL ACTIF Montant année N"
  },
  {
      "code": "A3.2",
      "traduction": "TOTAL ACTIF Montant année N-1"
  },
  {
      "code": "P1.1",
      "traduction": "PASSIF - Dettes envers les Ets de crédit Montant année N"
  },
  {
      "code": "P1.2",
      "traduction": "PASSIF - Dettes envers les Ets de crédit Montant année N-1"
  },
  {
      "code": "P2.1",
      "traduction": "PASSIF - Comptes créditeurs à la clientèle Montant année N"
  },
  {
      "code": "P2.2",
      "traduction": "PASSIF - Comptes créditeurs à la clientèle Montant année N-1"
  },
  {
      "code": "P3.1",
      "traduction": "PASSIF - Capital souscrit Montant année N"
  },
  {
      "code": "P3.2",
      "traduction": "PASSIF - Capital souscrit Montant année N-1"
  },
  {
      "code": "P4.1",
      "traduction": "PASSIF - Primes d’émission Montant année N"
  },
  {
      "code": "P4.2",
      "traduction": "PASSIF - Primes d’émission Montant année N-1"
  },
  {
      "code": "P5.1",
      "traduction": "PASSIF - Réserves Montant année N"
  },
  {
      "code": "P5.2",
      "traduction": "PASSIF - Réserves Montant année N-1"
  },
  {
      "code": "P6.1",
      "traduction": "PASSIF - Ecarts de réévaluation Montant année N"
  },
  {
      "code": "P6.2",
      "traduction": "PASSIF - Ecarts de réévaluation Montant année N-1"
  },
  {
      "code": "P7.1",
      "traduction": "PASSIF - Report à nouveau Montant année N"
  },
  {
      "code": "P7.2",
      "traduction": "PASSIF - Report à nouveau Montant année N-1"
  },
  {
      "code": "P8.1",
      "traduction": "PASSIF - Résultat de l’exercice Montant année N"
  },
  {
      "code": "P8.2",
      "traduction": "PASSIF - Résultat de l’exercice Montant année N-1"
  },
  {
      "code": "P9.1",
      "traduction": "TOTAL PASSIF Montant année N"
  },
  {
      "code": "P9.2",
      "traduction": "TOTAL PASSIF Montant année N-1"
  },
  {
      "code": "R1.1",
      "traduction": "Compte de résultat - Intérêts et produits assimilés Montant année N"
  },
  {
      "code": "R1.2",
      "traduction": "Compte de résultat - Intérêts et produits assimilés Montant année N-1"
  },
  {
      "code": "R2.1",
      "traduction": "Compte de résultat - Intérêts et charges assimilées Montant année N"
  },
  {
      "code": "R2.2",
      "traduction": "Compte de résultat - Intérêts et charges assimilées Montant année N-1"
  },
  {
      "code": "R3.1",
      "traduction": "Compte de résultat - Résultat de l’exercice Montant année N"
  },
  {
      "code": "R3.2",
      "traduction": "Compte de résultat - Résultat de l’exercice Montant année N-1"
  }
]