import React, { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { IoMdCloseCircle } from 'react-icons/io';
import { ImSearch } from 'react-icons/im';
import { NB_RESULTS_MAX } from '../constants/companyConstants';
import { DefaultQuery, NB_LIST_VALUE } from '../constants/queryConstants';
import { CodeAccountType } from '../enums/AccountType';
import { Critere } from '../models/Critere';
import { CustomItem } from '../models/CustomItem';
import { CustomQuery, FormCompanyQueryModel } from '../models/CustomQuery';
import { Tools } from '../screen/tools';
import AutoCompleteInputComponent from './AutocompleteInputText';
import { CustomPopOverTrigger } from './Tooltip';

import ADDRESS from '../data/address.json'
import CODE_ACTIVITES from '../data/activity_code.json'
import './formCompanyQuerys.scss'
import { Operators } from '../enums/Operator.enum';
import { MdArrowLeft, MdArrowRight, } from 'react-icons/md';
import { ERROR_EMPTY_ELEMENT, ERROR_EMPTY_QUERY, ERROR_QUERY_ALREADY_WRITE, ERROR_UNKNOW_QUERY_FILTER } from '../constants/errorConstants';
import { InputAutocompletType } from '../enums/Input.enum';
import './autocomplete.scss'
import AutoCompleteInputSelectComponent from './AutocompleteInputSelect';
import { AutocompleteItem } from '../models/autocomplete';
import { listCritereString } from '../data/string_attributs';
import { FaDownload } from 'react-icons/fa';
import { ModalTypes } from '../enums/ModalTypes.eum';

const formValid = (formErrors:any) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val:any) => {
    val && val.length > 0 && (valid = false);
  });

  return valid;
};

const FormCompanyQuerys: React.FC<{
  formCompanyQuery: FormCompanyQueryModel,changeFormCompanyQuery:any, handleSubmit: any, listCompanysByPage: any[], loading: boolean
}> = ({ formCompanyQuery, changeFormCompanyQuery, handleSubmit, listCompanysByPage, loading }) => {

  const [state, setState] = useState<FormCompanyQueryModel>(formCompanyQuery);
  const [helpQuery, setHelpQuery] = useState<boolean>(true);
  const { accountType, autocomplete, querys, currentQuery, page, listSize } = state;
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [listValueAutocomplete, setListValueAutocomplete] = useState<AutocompleteItem[]>([]);
  const [formErrors, setFormErrors] = useState<any>({
    code: null,
    critereName: null,
    operator: null,
    value: null,
  })
  const accountTypeRef = React.createRef<HTMLButtonElement>();
  const critereListRef = React.createRef<HTMLInputElement>();
  const critereOperatorRef = React.createRef<HTMLButtonElement>();
  const critereValueRef = React.createRef<HTMLInputElement>();
  const downloadRef = React.createRef<HTMLButtonElement>();
  const formQueryRef = React.createRef<HTMLFormElement>();
  const addQueryButtonRef = React.createRef<HTMLButtonElement>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  let timerErrorMesage: NodeJS.Timeout;

  const showErrorMesage = (message: string) => {
    setErrorMessage(message);
    timerErrorMesage = setTimeout(() => {
      setErrorMessage('');
    }, 3000)
  }

  const changeCurrentAccountType = (type: CodeAccountType) => {

    const criteres = Tools.getCriteresList(type);

    critereListRef.current?.focus();

    setState({
      ...state,
      accountType: type, criteres, currentQuery: { ...DefaultQuery },
      autocomplete: criteres,
      querys: [],
      listSize: 0,
      page: 1
    });

  }

  const changeCurrentCritere = (value: string) => {
    //const { name, value } = e.target;
    const { criteres, currentQuery } = state;

    const autocomplete: Critere[] = !value ? criteres : Tools.searchCritereInList(criteres, value);

    setState({
      ...state,
      autocomplete: autocomplete, currentQuery:
        { ...currentQuery, critereName: value }
    });
  }

  // return false if value or critere are incorrect
  // const  tt  = (): boolean => {
  //   const { criteres, currentQuery } = state;
  //   //const value = currentQuery.critereName;
  //   //console.log('currentQuery :>> ', currentQuery);
  //   const list = criteres.filter(cr => cr.traduction.toUpperCase() === currentQuery.critereName.toUpperCase());
  //   const critere = list ? list[0] : null;
  //   return critere !== null;
  // }
  
  const changeCurrentOperator = (value: Operators) => {
    //const { value } = e.target;
    const { currentQuery } = state;

    critereValueRef.current?.focus();
    //console.log('value :>> ', value);
    setState({ ...state, currentQuery: { ...currentQuery, operator: value } });
  }

  const changeCurrentValue = (value: string) => {
    //const { value } = e.target;
    const { currentQuery } = state;


    setState({ ...state, currentQuery: { ...currentQuery, value: value } });
  }

  const selectCritereOfList = (critere: Critere) => {

    const { currentQuery } = state;
    let operator = currentQuery.operator;
    if (!Tools.isStringValue(critere.code)) {
      critereOperatorRef.current?.focus();
    }
    else {
      operator = Operators.EGALE;
      critereValueRef.current?.focus();
    }

    setState({
      ...state,
      currentQuery: {
        ...currentQuery,
        code: critere.code,
        critereName: critere.traduction, 
        operator
      },
    });
  }

  const selectValueOfList = (value: string) => {

    const { currentQuery } = state;
    //console.log(value);

    addQueryButtonRef.current?.focus();
    setState({
      ...state,
      currentQuery: { ...currentQuery, value: value },
    });
  }

  const getListValueAutocomplete = (critere: string): AutocompleteItem[] => {
    //console.log('enter critere :>> ', critere);
    switch (critere) {
      case "adresse":
        //console.log('adresse :>> ');
        return Tools.searchStringOfList(ADDRESS, currentQuery.value, NB_LIST_VALUE).map((txt: string) => Tools.formatItemStringToItem(txt));
      //return Tools.searchStringOfList(ADDRESS, currentQuery.value, NB_LIST_VALUE).map((txt: string) => Tools.formatItemStringToItem(txt));
      case "code_activite":
        //console.log("code_activite");
        return Tools.searchElementInList(CODE_ACTIVITES, currentQuery.value, NB_LIST_VALUE).map((element: CustomItem) => Tools.formatCustomItemToItem(element));
      default:
        //console.log("vide");
        return []
    }
  }


  const addQuery = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const { currentQuery, querys, criteres } = state;

    const list = criteres.filter(cr => cr.traduction.toUpperCase() === currentQuery.critereName.toUpperCase());
    const critere = list ? list[0] : null;
    //console.log("critere : " + JSON.stringify(critere) + " operator : " + currentQuery.operator);
    const newFormErrors = {...formErrors};

    // if critere is correct
    if(critere?.traduction)
      newFormErrors.critereName = '';
    else
      newFormErrors.critereName = ERROR_UNKNOW_QUERY_FILTER;
    
    if(currentQuery.value)
      newFormErrors.value = '';
    else
      newFormErrors.value = ERROR_EMPTY_ELEMENT;
      
    //console.log('newFormErrors :>> ', newFormErrors);
    setFormErrors({ ...newFormErrors });

    // if error is empty
    if(formValid(newFormErrors)){
      const indexQuery = Tools.getIndexQuery(currentQuery.critereName, currentQuery.operator, state.querys);
      //console.log('indexQuery :>> ', state.querys);
      if (indexQuery === -1) {
        querys.push({
              ...currentQuery,
              code: currentQuery.code, critereName: currentQuery.critereName,
              value: currentQuery.value,
              //operator: operator
            })
        } // if value is different update query
        else if (currentQuery.value !== state.querys[indexQuery].value){
            //console.log("update / query");
            querys[indexQuery].value = currentQuery.value;
        } // we can't add query already exist
        else {
          showErrorMesage(`${ERROR_QUERY_ALREADY_WRITE}`);
        }
    }
    setState({ ...state, querys });
  }

  // select query in filters list to update 
  const selectedQuery = (critere: string, operator: string) => {
    if (Tools.getIndexQuery(critere, operator, state.querys) !== -1)
      setState({ ...state, currentQuery: state.querys[Tools.getIndexQuery(critere, operator, state.querys)] });
  }

  const removeQuery = (critere: string, operator: string) => {
    const { querys } = state;

    //console.log("current index : " + Tools.getIndexQuery(critere, operator));

    querys.splice(Tools.getIndexQuery(critere, operator, state.querys), 1);

    setState({ ...state, querys });
  }


  // return true if we have already search this page of this request
  const pageExist = (page: number): boolean => {
    //console.log('listCompanysByPage[page] !== undefined :>> ', listCompanysByPage[page] !== undefined);
    return listCompanysByPage[page] !== undefined;
  }

  const changePage = (page: number) => {
    //console.log('change page :>> ', page);
    if (page > 0) {
      //console.log("object suivant ",page);
      const listSize = listCompanysByPage[page]?.length;
      const newState = { ...state, listSize, page};
      if (!pageExist(page)) {
        //console.log('page :>> ', page);
  
        handleSubmit(newState);
      }
      else{
        changeFormCompanyQuery(newState);
      }
    }
  }

  const resize = () => {
    setIsSmallScreen(window.innerWidth <= 760);
  }

  const submit = () => {
    if (state.querys.length > 0) {
      handleSubmit(state);
    }
    else {
      showErrorMesage(ERROR_EMPTY_QUERY)
    }
  }

  useEffect(() => {
    setListValueAutocomplete(getListValueAutocomplete(currentQuery.code));
  }, [currentQuery.code, currentQuery.value]);

  useEffect(() => {
    setState(formCompanyQuery);
  },[formCompanyQuery])
  
  useEffect(() => {
    window.addEventListener("resize", resize.bind(this));
    resize();

    return (() => {
      clearTimeout(timerErrorMesage);
      window.removeEventListener("resize", resize.bind(this));
    })
  }, []);

  
  // const openModalDownload = () => {
  //   //const { currentApiQuery } = this.state;

  //   const { showModal } = thiscontext;
  //   //console.log('currentApiQuery :>> ', currentApiQuery);

  //   showModal({
  //     modalType: ModalTypes.DOWNLOAD,
  //     currentApiQuery
  //   });

  // }

  // print 2 columns
  return (
    <div className="">
      <div className="container">
        <div className="d-flex flex-wrap">

          {/*recaptulatif + input avance*/}
          <div id="queryResume" className="d-flex flex-wrap">
            <div className='div-querys-add d-flex flex-wrap'>

            {querys.map((q: CustomQuery) =>
              <div className="query d-flex" key={q.critereName + q.operator}
              onClick={() => selectedQuery(q.critereName, q.operator)}>
                {q.critereName + " " + q.operator + " " + q.value}
                &ensp;
                <div className="custom-icon">
                  <IoMdCloseCircle size={30} onClick={() => removeQuery(q.critereName, q.operator)} />
                </div>
              </div>
            )}
            </div>

            <button tabIndex={6} type="submit" onClick={() => submit()} disabled={loading}
              className=" btn btn-light ml-auto mb-auto mr-2 border" >
                {/* Search Button */}
              <ImSearch size={25} color={"#4f7dc0"} />
            </button>
            {/* <button tabIndex={6} type="submit" onClick={() => handleSubmit(state)}
              className="col-4 col-md-2 btn btn-primary ml-auto mb-auto mr-2" >
              rechercher
            </button> */}
            <div className='col-12 d-flex justify-content-center'>
              <p className='text-error mr-auto'>
              {errorMessage}
              </p>
            </div>

            {/* form query */}
            <form ref={formQueryRef} onSubmit={addQuery} className="col-12 d-md-flex flex-md-equal my-md-3 ps-md-3 justify-content-center bg-light p-3">

              <div className="p-2 ">
                <CustomPopOverTrigger
                  reference={formQueryRef}
                  target={accountTypeRef}
                  show={helpQuery}
                  placement={isSmallScreen ? "top" : "left"}
                  title={"Type de Compte"}
                  content={'Les types de comptes correspondent au format sur lequel les comptes sont communiqués.'}
                >
                  <div>
                    <AutoCompleteInputSelectComponent
                      propsInput={{
                        tabIndex: 1, ref: accountTypeRef,
                        value: accountType,
                        name: "accountType",
                      }}
                      propsList={{
                        show: true,
                        tabIndex: 2,
                        items: Object.keys(CodeAccountType).map((at: string) => {
                          return { code: at, value: Object(CodeAccountType)[at], data: at }
                        }),
                        selectItem: (at: any) => changeCurrentAccountType(at)
                      }}
                    />
                  </div>
                </CustomPopOverTrigger>

              </div>
              <div className="p-2">
                <CustomPopOverTrigger
                  reference={formQueryRef}
                  target={critereListRef}
                  show={helpQuery}
                  placement={isSmallScreen ? "top" : "left"}
                  title={"Critere"} content={'Les critères sont les champs à choisir pour filtrer les résultats.'}
                >
                  <div>
                    <AutoCompleteInputComponent
                      onChange={(critere: string) => changeCurrentCritere(critere)}
                      propsInput={{
                        tabIndex: 2, ref: critereListRef,
                        value: currentQuery.critereName,
                        name: "critereName", placeholder: "champ..."
                      }}
                      propsList={{
                        //show:autocomplete && critereIsFocus,
                        tabIndex: 2,
                        show: true,
                        items: autocomplete.map(cr => Tools.formatItemCritereToItem(cr)),
                        selectItem: (cr: any) => selectCritereOfList(cr)
                      }}
                    />
                  </div>
                </CustomPopOverTrigger>
                <div className='text-error'>
                  {formErrors.critereName}
                </div>
              </div>

              <div className=" p-2">
                <CustomPopOverTrigger
                  reference={formQueryRef}
                  show={helpQuery}
                  target={critereOperatorRef}
                  placement={isSmallScreen ? "top" : "left-end"}
                  title={"Operateur"}
                  content={'Les opérateurs sont des signes qui désignent les opérations à effectuer sur les critères'}
                >
                  <div>
                    <AutoCompleteInputSelectComponent
                      propsInput={{
                        tabIndex: 3, ref: critereOperatorRef,
                        value: currentQuery.operator,
                        name: "operator"
                      }}
                      propsList={{
                        show: true,
                        tabIndex: 2,
                        items: Tools.isStringValue(currentQuery.code) ? [] : Object.keys(Operators).map((at: string) => {
                          return { code: at, value: Object(Operators)[at], data: Object(Operators)[at] }
                        }),
                        selectItem: (at: any) => changeCurrentOperator(at)
                      }}
                    />
                  </div>

                </CustomPopOverTrigger>
              </div>

              {/* <div className="px-md-2 text-center mt-auto mb-auto overflow-hidden ">
                à
              </div> */}

              <div className="p-2">
                <CustomPopOverTrigger
                  reference={formQueryRef}
                  show={helpQuery}
                  target={critereValueRef}
                  placement={isSmallScreen ? "top" : "left-end"}
                  title={"Valeur"}
                  content={'La valeur est celle à définir en fonction du critère, pour la requête'}
                >
                  <div>
                    <AutoCompleteInputComponent
                      onChange={(value: string) => changeCurrentValue(value)}
                      inputType={Tools.isStringValue(currentQuery.code) ? "text" : "number"}
                      propsInput={{
                        tabIndex: 4, ref: critereValueRef, name: "value", placeholder: "valeur...",
                        value:  Tools.isStringValue(currentQuery.code) ? currentQuery.value : parseInt(currentQuery.value) || '',
                        autoComplete: (Tools.isCustomList(currentQuery.code)) ? "on" : "off"
                      }}

                      propsList={{
                        tabIndex: 4, show: true,
                        items: listValueAutocomplete,
                        selectItem: (code: string) => selectValueOfList(code)
                      }}
                    />
                  </div>

                </CustomPopOverTrigger>
                <div className='text-error'>
                  {formErrors.value}
                </div>
              </div>

              <div className='px-md-2 mt-3 text-center'>
                <button tabIndex={5} ref={addQueryButtonRef} className="custom-icon"
                >
                  <BsPlusCircleFill size={30} color={"grey"} onClick={addQuery} />
                </button>
              </div>
            </form>

                    <div className='d-flex justify-content-between'>
            {helpQuery &&
              <button className='btn btn-light mr-4' onClick={() => setHelpQuery(false)}>J'ai compris</button>
            }
          <div className='d-flex justify-content-end'>
          {/* <CustomPopOverTrigger
                  reference={formQueryRef}
                  show={helpQuery}
                  target={critereValueRef}
                  placement={"top"}
                  title={"Valeur"}
                  content={'La valeur est celle à définir en fonction du critère, pour la requête'}
                > */}
            {/* {<ModalDownloadCSV apiQquery={currentApiQuery} 
            hideModal={undefined} />} */}

            {/* <button className='btn btn-ligth' disabled={!currentApiQuery}
              onClick={() => openModalDownload()}
              ref={downloadRef}
              >
              <FaDownload size={25} color="green" />
            </button>
          </CustomPopOverTrigger> */}

          </div>
        </div>
          </div>
        </div>

      </div>


      <div className="container d-flex justify-content-between mt-3 text-center">

        <div className="">
          <button type="button" onClick={() => changePage(page - 1)}
            disabled={!listCompanysByPage[(page - 1)]}
            className={" btn ml-auto mb-auto mr-2 border " + (!listCompanysByPage[(page - 1)] ? "btn-light" : "btn-primary")}  >
            <MdArrowLeft size={30} />
          </button>

          {/* <CustomButtom style={BtnStyle.BLUE}
            action={() => changePage(page - 1)} active={!listCompanysByPage[(page - 1)]}>
            <MdArrowLeft size={20} />

          </CustomButtom> */}
        </div>

        <div ><p className='font-weight-bold text-secondary p-2 '> Page : {page} </p></div>

        <div className="">
          <button type="button" onClick={() => changePage(page + 1)}
            disabled={!(listCompanysByPage[page]?.length >= NB_RESULTS_MAX)}
            className={" btn ml-auto mb-auto mr-2 border " + (!(listCompanysByPage[page]?.length >= NB_RESULTS_MAX) ? "btn-light" : "btn-primary")}  >
            <MdArrowRight size={30} />
          </button>

          {/* <CustomButtom style={BtnStyle.BLUE}
            action={() => changePage(page + 1)} active={listCompanysByPage[page]?.length >= NB_RESULTS_MAX}>
            <MdArrowRight size={30} />
          </CustomButtom> */}
        </div>
      </div>
    </div>
  );

}

export default FormCompanyQuerys;