import React, { useEffect, useState } from 'react';
import { BsBookmarkHeartFill } from 'react-icons/bs';
import { FaHistory } from 'react-icons/fa';
import { usePapaParse } from 'react-papaparse';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ModalTypes } from '../enums/ModalTypes.eum';
import { ModalContext } from '../ModalContext';
import { ApiCustomQuery } from '../models/CustomQuery';
import { Tools } from '../screen/tools';

function HistoryButtons(props: any) {

    const [historical, setHistorical] = useState<ApiCustomQuery[]>([]);
    const companysHistorique: any = useSelector((state: RootStateOrAny) => state.companysHistorique);
    const { showModal }: any = React.useContext(ModalContext);
    const [showHistorical, setShowHistorical] = useState<boolean>(false);
    const [listHistoryNode, setListHistoryNode] = useState<any>();

    const showModalHistorical = (listApiCustomQuery: ApiCustomQuery[]): void => {

        showModal({
            modalType: ModalTypes.HISTORY,
            historical: listApiCustomQuery, history: props.history
        });

    }
/*  Format CSV QUERY
    const { readRemoteFile } = usePapaParse();

    useEffect(() => {
        const urlCSV_A = "canvas/querys/Canevas_1_A.csv";
        const urlCSV_B = "canvas/querys/Canevas_1_B.csv";
        const urlCSV_C = "canvas/querys/Canevas_1_C.csv";
        const urlCSV_S = "canvas/querys/Canevas_1_S.csv";
        const urlCSV_K = "canvas/querys/Canevas_1_K.csv";

        readRemoteFile(urlCSV_S, {
            complete: (results) => {
                console.log('---------------------------');
                //console.log('Results:', results);
                //console.log('format csv :>> ', Tools.formatCSVCanvasA_B(4,27,results.data));
                //console.log('format csv :>> ', Tools.formatCSVCanvasA_B(4,33,results.data));
                //console.log('format csv :>> ', Tools.formatCSVCanvasK(results.data));
                //console.log('format csv :>> ', Tools.formatCSVCanvasS(results.data));
                console.log('---------------------------');
            },
            download: true
        });


    }, [])
 */
    const getListHistoryNode = (history: ApiCustomQuery[]) => {
        return history.map((item: ApiCustomQuery, index: number) =>
        <div  key={index + "history" + item.date}>

            <Link to={Tools.getUrlSearchCompany(item)} type="button"
                className="list-group-item list-group-item-action"
            >
                {index + "history" + item.date}
                Tps : {item.reqestTime} s
                <span className=''> Type : {item.accountType} </span>
                Page : {item.page}
                <br />
                <span className='border-bottom'> Date {item.date} </span>

                <br />
                {Tools.getListFormatQueryText(item.listQuerys, 40).map(text =>
                    <span>
                        {text}
                    </span>
                )}
            </Link>
                </div>
            )
    }

    useEffect(() => {
        if (historical.length > 0) {
            console.log('Enter !! historical :>> ', historical);
            setListHistoryNode(getListHistoryNode(historical.reverse()));
        }
    }, [historical]);

    useEffect(() => {
        // console.log(companysHistorique);
        // console.log(companysHistorique.DC_Historical);
        if (companysHistorique.DC_Historical) {
            setHistorical(companysHistorique.DC_Historical)
        }
    }, [companysHistorique])

    return (
        <div className='container d-flex justify-content-end'>
            <div className='px-md-2 mt-2 text-center'>
                <button
                    className="custom-icon">
                    <BsBookmarkHeartFill size={18}
                        color="grey"
                        onClick={() => console.log("show History")} />
                </button>
            </div>

            <div style={{ position: 'relative'}} className=' px-md-2 mt-2 text-left mr-4 '
                onMouseLeave={() => setShowHistorical(false)}
                onMouseEnter={() => setShowHistorical(true)}
            >
                <button
                    className="custom-icon" onClick={() => showModalHistorical(historical)}>
                    <span className='text-secondary'>{historical?.length}</span>
                    <FaHistory size={18}
                        color="grey"
                    />
                </button>
                {showHistorical &&
                    <div className={"history-list bg-success " + (showHistorical ? "" : "hide")} >
                        {listHistoryNode}
                    </div>
                }


            </div>

        </div>
    );

}

export default HistoryButtons;

