import { Critere } from "../models/Critere";

// [
//     {
//       code : "R1.1",
//       traduction : "Compte de résultat - Primes - Cotisations acquises"
//     },
//     {
//       code : "R2.1",
//       traduction : "Compte de résultat - Charges des sinistres"
//     },
//     {
//       code : "R3.1",
//       traduction : "Compte de résultat - Résultat technique"
//     },
//     {
//       code : "R4.3",
//       traduction : "Compte de résultat - Résultat de l’exercice"
//     },
//     {
//       code : "A1.1",
//       traduction : "ACTIF - Placements"
//     },
//     {
//       code: "A2.1",
//       traduction: "TOTAL ACTIF"
//     },
//     {
//       code : "P1.1",
//       traduction : "PASSIF - Capitaux propres"
//     },
//     {
//       code : "P2.1",
//       traduction : "PASSIF - Provisions techniques brutes"
//     },
//     {
//       code : "P3.1",
//       traduction : "TOTAL PASSIFs"
//     }
//   ];

export const listCriteresAssurance: Critere[] = [
  {
      "code": "siren",
      "traduction": "siren"
  },
  {
      "code": "date_cloture_exercice",
      "traduction": "date_cloture_exercice"
  },
  {
      "code": "code_greffe",
      "traduction": "code_greffe"
  },
  {
      "code": "num_depot",
      "traduction": "num_depot"
  },
  {
      "code": "num_gestion",
      "traduction": "num_gestion"
  },
  {
      "code": "code_activite",
      "traduction": "code_activite"
  },
  {
      "code": "date_cloture_exercice_n_precedent",
      "traduction": "date_cloture_exercice_n_precedent"
  },
  {
      "code": "duree_exercice_n",
      "traduction": "duree_exercice_n"
  },
  {
      "code": "duree_exercice_n_precedent",
      "traduction": "duree_exercice_n_precedent"
  },
  {
      "code": "date_depot",
      "traduction": "date_depot"
  },
  {
      "code": "code_motif",
      "traduction": "code_motif"
  },
  {
      "code": "code_type_bilan",
      "traduction": "code_type_bilan"
  },
  {
      "code": "code_devise",
      "traduction": "code_devise"
  },
  {
      "code": "code_origine_devise",
      "traduction": "code_origine_devise"
  },
  {
      "code": "code_confidentialite",
      "traduction": "code_confidentialite"
  },
  {
      "code": "denomination",
      "traduction": "denomination"
  },
  {
      "code": "adresse",
      "traduction": "adresse"
  },
  {
      "code": "COMPTES ANNUELS.1",
      "traduction": "Montant année N"
  },
  {
      "code": "COMPTES ANNUELS.2",
      "traduction": "Montant année N-1"
  },
  {
      "code": "A1.1",
      "traduction": "ACTIF - Placements Montant année N"
  },
  {
      "code": "A1.2",
      "traduction": "ACTIF - Placements Montant année N-1"
  },
  {
      "code": "A2.1",
      "traduction": "TOTAL ACTIF Montant année N"
  },
  {
      "code": "A2.2",
      "traduction": "TOTAL ACTIF Montant année N-1"
  },
  {
      "code": "P1.1",
      "traduction": "PASSIF - Capitaux propres Montant année N"
  },
  {
      "code": "P1.2",
      "traduction": "PASSIF - Capitaux propres Montant année N-1"
  },
  {
      "code": "P2.1",
      "traduction": "PASSIF - Provisions techniques brutes Montant année N"
  },
  {
      "code": "P2.2",
      "traduction": "PASSIF - Provisions techniques brutes Montant année N-1"
  },
  {
      "code": "P3.1",
      "traduction": "TOTAL PASSIF Montant année N"
  },
  {
      "code": "P3.2",
      "traduction": "TOTAL PASSIF Montant année N-1"
  },
  {
      "code": "R1.1",
      "traduction": "Compte de résultat - Primes - Cotisations acquises Montant année N"
  },
  {
      "code": "R1.2",
      "traduction": "Compte de résultat - Primes - Cotisations acquises Montant année N-1"
  },
  {
      "code": "R2.1",
      "traduction": "Compte de résultat - Charges des sinistres Montant année N"
  },
  {
      "code": "R2.2",
      "traduction": "Compte de résultat - Charges des sinistres Montant année N-1"
  },
  {
      "code": "R3.1",
      "traduction": "Compte de résultat - Résultat technique Montant année N"
  },
  {
      "code": "R3.2",
      "traduction": "Compte de résultat - Résultat technique Montant année N-1"
  },
  {
      "code": "R4.1",
      "traduction": "Compte de résultat - Résultat de l’exercice Montant année N"
  },
  {
      "code": "R4.2",
      "traduction": "Compte de résultat - Résultat de l’exercice Montant année N-1"
  }
]
