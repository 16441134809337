import { Critere } from "../models/Critere";

/*
[
    {
      code : "232.1",
      traduction : "Total des produits d’exploitation hors T.V.A"
    },
    {
      code : "270.1",
      traduction : "Résultat d’exploitation"
    },
    {
      code : "310.1",
      traduction : "Résultat net"
    },
    {
      code : "110.3",
      traduction : "Total Actif Net"
    },
    {
      code : "044.3",
      traduction : "Immobilisations Nettes"
    },
    {
      code: "096.3",
      traduction: "Actifs Circulants et CCA Nettes"
    },
    {
      code : "142.3",
      traduction : "Capitaux propres"
    },
    {
      code : "176",
      traduction : "Total Dettes"
    },

  ];
  */
export const listCriteresSimple: Critere[] = [
  {
      "code": "siren",
      "traduction": "siren"
  },
  {
      "code": "date_cloture_exercice",
      "traduction": "date_cloture_exercice"
  },
  {
      "code": "code_greffe",
      "traduction": "code_greffe"
  },
  {
      "code": "num_depot",
      "traduction": "num_depot"
  },
  {
      "code": "num_gestion",
      "traduction": "num_gestion"
  },
  {
      "code": "code_activite",
      "traduction": "code_activite"
  },
  {
      "code": "date_cloture_exercice_n_precedent",
      "traduction": "date_cloture_exercice_n_precedent"
  },
  {
      "code": "duree_exercice_n",
      "traduction": "duree_exercice_n"
  },
  {
      "code": "duree_exercice_n_precedent",
      "traduction": "duree_exercice_n_precedent"
  },
  {
      "code": "date_depot",
      "traduction": "date_depot"
  },
  {
      "code": "code_motif",
      "traduction": "code_motif"
  },
  {
      "code": "code_type_bilan",
      "traduction": "code_type_bilan"
  },
  {
      "code": "code_devise",
      "traduction": "code_devise"
  },
  {
      "code": "code_origine_devise",
      "traduction": "code_origine_devise"
  },
  {
      "code": "code_confidentialite",
      "traduction": "code_confidentialite"
  },
  {
      "code": "denomination",
      "traduction": "denomination"
  },
  {
      "code": "adresse",
      "traduction": "adresse"
  },
  {
      "code": "A.1",
      "traduction": "Montant brut année N"
  },
  {
      "code": "A.2",
      "traduction": "Amortissement année N"
  },
  {
      "code": "A.3",
      "traduction": "Net année N"
  },
  {
      "code": "A.4",
      "traduction": "Net année N-1"
  },
  {
      "code": "010.1",
      "traduction": "Immobilisations incorporelles - Fonds commercial Montant brut année N"
  },
  {
      "code": "010.2",
      "traduction": "Immobilisations incorporelles - Fonds commercial Amortissement année N"
  },
  {
      "code": "010.3",
      "traduction": "Immobilisations incorporelles - Fonds commercial Net année N"
  },
  {
      "code": "010.4",
      "traduction": "Immobilisations incorporelles - Fonds commercial Net année N-1"
  },
  {
      "code": "014.1",
      "traduction": "Immobilisations incorporelles - Autres Montant brut année N"
  },
  {
      "code": "014.2",
      "traduction": "Immobilisations incorporelles - Autres Amortissement année N"
  },
  {
      "code": "014.3",
      "traduction": "Immobilisations incorporelles - Autres Net année N"
  },
  {
      "code": "014.4",
      "traduction": "Immobilisations incorporelles - Autres Net année N-1"
  },
  {
      "code": "028.1",
      "traduction": "Immobilisations corporelles Montant brut année N"
  },
  {
      "code": "028.2",
      "traduction": "Immobilisations corporelles Amortissement année N"
  },
  {
      "code": "028.3",
      "traduction": "Immobilisations corporelles Net année N"
  },
  {
      "code": "028.4",
      "traduction": "Immobilisations corporelles Net année N-1"
  },
  {
      "code": "040.1",
      "traduction": "Immobilisations financières Montant brut année N"
  },
  {
      "code": "040.2",
      "traduction": "Immobilisations financières Amortissement année N"
  },
  {
      "code": "040.3",
      "traduction": "Immobilisations financières Net année N"
  },
  {
      "code": "040.4",
      "traduction": "Immobilisations financières Net année N-1"
  },
  {
      "code": "044.1",
      "traduction": "Total Actif Immobilisé Montant brut année N"
  },
  {
      "code": "044.2",
      "traduction": "Total Actif Immobilisé Amortissement année N"
  },
  {
      "code": "044.3",
      "traduction": "Total Actif Immobilisé Net année N"
  },
  {
      "code": "044.4",
      "traduction": "Total Actif Immobilisé Net année N-1"
  },
  {
      "code": "050.1",
      "traduction": "Matières premières, approvisionnements, en cours de production Montant brut année N"
  },
  {
      "code": "050.2",
      "traduction": "Matières premières, approvisionnements, en cours de production Amortissement année N"
  },
  {
      "code": "050.3",
      "traduction": "Matières premières, approvisionnements, en cours de production Net année N"
  },
  {
      "code": "050.4",
      "traduction": "Matières premières, approvisionnements, en cours de production Net année N-1"
  },
  {
      "code": "060.1",
      "traduction": "Stock marchandises Montant brut année N"
  },
  {
      "code": "060.2",
      "traduction": "Stock marchandises Amortissement année N"
  },
  {
      "code": "060.3",
      "traduction": "Stock marchandises Net année N"
  },
  {
      "code": "060.4",
      "traduction": "Stock marchandises Net année N-1"
  },
  {
      "code": "064.1",
      "traduction": "Avances et acomptes versés sur commandes Montant brut année N"
  },
  {
      "code": "064.2",
      "traduction": "Avances et acomptes versés sur commandes Amortissement année N"
  },
  {
      "code": "064.3",
      "traduction": "Avances et acomptes versés sur commandes Net année N"
  },
  {
      "code": "064.4",
      "traduction": "Avances et acomptes versés sur commandes Net année N-1"
  },
  {
      "code": "068.1",
      "traduction": "Créances - Clients et comptes rattachés Montant brut année N"
  },
  {
      "code": "068.2",
      "traduction": "Créances - Clients et comptes rattachés Amortissement année N"
  },
  {
      "code": "068.3",
      "traduction": "Créances - Clients et comptes rattachés Net année N"
  },
  {
      "code": "068.4",
      "traduction": "Créances - Clients et comptes rattachés Net année N-1"
  },
  {
      "code": "072.1",
      "traduction": "Créances - Autres Montant brut année N"
  },
  {
      "code": "072.2",
      "traduction": "Créances - Autres Amortissement année N"
  },
  {
      "code": "072.3",
      "traduction": "Créances - Autres Net année N"
  },
  {
      "code": "072.4",
      "traduction": "Créances - Autres Net année N-1"
  },
  {
      "code": "080.1",
      "traduction": "Valeurs mobilières de placement Montant brut année N"
  },
  {
      "code": "080.2",
      "traduction": "Valeurs mobilières de placement Amortissement année N"
  },
  {
      "code": "080.3",
      "traduction": "Valeurs mobilières de placement Net année N"
  },
  {
      "code": "080.4",
      "traduction": "Valeurs mobilières de placement Net année N-1"
  },
  {
      "code": "084.1",
      "traduction": "Disponibilités Montant brut année N"
  },
  {
      "code": "084.2",
      "traduction": "Disponibilités Amortissement année N"
  },
  {
      "code": "084.3",
      "traduction": "Disponibilités Net année N"
  },
  {
      "code": "084.4",
      "traduction": "Disponibilités Net année N-1"
  },
  {
      "code": "088.1",
      "traduction": "Caisse Montant brut année N"
  },
  {
      "code": "088.2",
      "traduction": "Caisse Amortissement année N"
  },
  {
      "code": "088.3",
      "traduction": "Caisse Net année N"
  },
  {
      "code": "088.4",
      "traduction": "Caisse Net année N-1"
  },
  {
      "code": "092.1",
      "traduction": "Charges constatées d'avance Montant brut année N"
  },
  {
      "code": "092.2",
      "traduction": "Charges constatées d'avance Amortissement année N"
  },
  {
      "code": "092.3",
      "traduction": "Charges constatées d'avance Net année N"
  },
  {
      "code": "092.4",
      "traduction": "Charges constatées d'avance Net année N-1"
  },
  {
      "code": "096.1",
      "traduction": "Total Actif circulant + Charges constatées d'avance Montant brut année N"
  },
  {
      "code": "096.2",
      "traduction": "Total Actif circulant + Charges constatées d'avance Amortissement année N"
  },
  {
      "code": "096.3",
      "traduction": "Total Actif circulant + Charges constatées d'avance Net année N"
  },
  {
      "code": "096.4",
      "traduction": "Total Actif circulant + Charges constatées d'avance Net année N-1"
  },
  {
      "code": "110.1",
      "traduction": "Total général Actif Montant brut année N"
  },
  {
      "code": "110.2",
      "traduction": "Total général Actif Amortissement année N"
  },
  {
      "code": "110.3",
      "traduction": "Total général Actif Net année N"
  },
  {
      "code": "110.4",
      "traduction": "Total général Actif Net année N-1"
  },
  {
      "code": "P.4",
      "traduction": "Net année N-1"
  },
  {
      "code": "120.4",
      "traduction": "Capital social ou individuel Net année N-1"
  },
  {
      "code": "124.4",
      "traduction": "Ecarts de réévaluation Net année N-1"
  },
  {
      "code": "126.4",
      "traduction": "Réserve légale Net année N-1"
  },
  {
      "code": "130.4",
      "traduction": "Réserves règlementées Net année N-1"
  },
  {
      "code": "132.4",
      "traduction": "Autres réserves Net année N-1"
  },
  {
      "code": "134.4",
      "traduction": "Report à nouveau Net année N-1"
  },
  {
      "code": "136.4",
      "traduction": "Résultat de l'exercice Net année N-1"
  },
  {
      "code": "140.4",
      "traduction": "Provisions réglementées Net année N-1"
  },
  {
      "code": "142.4",
      "traduction": "Total des capitaux propres - Total I Net année N-1"
  },
  {
      "code": "154.4",
      "traduction": "Provisions pour risques et charges - Total II Net année N-1"
  },
  {
      "code": "156.4",
      "traduction": "Emprunts et dettes assimilées Net année N-1"
  },
  {
      "code": "164.4",
      "traduction": "Avances et acomptes reçus sur commandes en cours Net année N-1"
  },
  {
      "code": "166.4",
      "traduction": "Fournisseurs et comptes rattachés Net année N-1"
  },
  {
      "code": "169.4",
      "traduction": "Autres dettes dont comptes courant d'associés de l'exercice N Net année N-1"
  },
  {
      "code": "172.4",
      "traduction": "Autres dettes Net année N-1"
  },
  {
      "code": "174.4",
      "traduction": "Produits constatés d'avance Net année N-1"
  },
  {
      "code": "176.4",
      "traduction": "Total des dettes Net année N-1"
  },
  {
      "code": "180.4",
      "traduction": "Total général Passif Net année N-1"
  },
  {
      "code": "193.4",
      "traduction": "Immobilisations financières à moins d'un an (Inclus dans Total général Passif) Net année N-1"
  },
  {
      "code": "195.4",
      "traduction": "Dettes à plus d'un an (Inclus dans Total général Passif) Net année N-1"
  },
  {
      "code": "197.4",
      "traduction": "Créances à plus d'un an (Inclus dans Total général Passif) Net année N-1"
  },
  {
      "code": "182.4",
      "traduction": "Coût de revient des immobilisations acquises ou créées au cours de l'exercice (Inclus dans Total général Passif) Net année N-1"
  },
  {
      "code": "199.4",
      "traduction": "Comptes courant d'associés débiteurs (Inclus dans Total général Passif) Net année N-1"
  },
  {
      "code": "184.4",
      "traduction": "Prix de vente hors T.V.A. des immobilisations cédées au cours de l'exercice (Inclus dans Total général Passif) Net année N-1"
  },
  {
      "code": "CDR.1",
      "traduction": "Montant année N"
  },
  {
      "code": "CDR.2",
      "traduction": "Montant année N-1"
  },
  {
      "code": "209.1",
      "traduction": "Ventes de marchandises - Export Montant année N"
  },
  {
      "code": "209.2",
      "traduction": "Ventes de marchandises - Export Montant année N-1"
  },
  {
      "code": "215.1",
      "traduction": "Production vendue de biens - Export Montant année N"
  },
  {
      "code": "215.2",
      "traduction": "Production vendue de biens - Export Montant année N-1"
  },
  {
      "code": "217.1",
      "traduction": "Production vendue de services - Export Montant année N"
  },
  {
      "code": "217.2",
      "traduction": "Production vendue de services - Export Montant année N-1"
  },
  {
      "code": "210.1",
      "traduction": "Ventes de marchandises - France Montant année N"
  },
  {
      "code": "210.2",
      "traduction": "Ventes de marchandises - France Montant année N-1"
  },
  {
      "code": "214.1",
      "traduction": "Production vendue de biens - France Montant année N"
  },
  {
      "code": "214.2",
      "traduction": "Production vendue de biens - France Montant année N-1"
  },
  {
      "code": "218.1",
      "traduction": "Production vendue de services - France Montant année N"
  },
  {
      "code": "218.2",
      "traduction": "Production vendue de services - France Montant année N-1"
  },
  {
      "code": "222.1",
      "traduction": "Production stockée Montant année N"
  },
  {
      "code": "222.2",
      "traduction": "Production stockée Montant année N-1"
  },
  {
      "code": "224.1",
      "traduction": "Production immobilisée Montant année N"
  },
  {
      "code": "224.2",
      "traduction": "Production immobilisée Montant année N-1"
  },
  {
      "code": "226.1",
      "traduction": "Subventions d'exploitation reçues Montant année N"
  },
  {
      "code": "226.2",
      "traduction": "Subventions d'exploitation reçues Montant année N-1"
  },
  {
      "code": "230.1",
      "traduction": "Autres produits Montant année N"
  },
  {
      "code": "230.2",
      "traduction": "Autres produits Montant année N-1"
  },
  {
      "code": "232.1",
      "traduction": "Total des produits d'exploitation hors T.V.A. Montant année N"
  },
  {
      "code": "232.2",
      "traduction": "Total des produits d'exploitation hors T.V.A. Montant année N-1"
  },
  {
      "code": "234.1",
      "traduction": "Achats de marchandises (y compris droits de douane) Montant année N"
  },
  {
      "code": "234.2",
      "traduction": "Achats de marchandises (y compris droits de douane) Montant année N-1"
  },
  {
      "code": "236.1",
      "traduction": "Variation de stock (marchandises) Montant année N"
  },
  {
      "code": "236.2",
      "traduction": "Variation de stock (marchandises) Montant année N-1"
  },
  {
      "code": "238.1",
      "traduction": "Achats de matières premières et autres approvisionnements (y compris droits de douane) Montant année N"
  },
  {
      "code": "238.2",
      "traduction": "Achats de matières premières et autres approvisionnements (y compris droits de douane) Montant année N-1"
  },
  {
      "code": "240.1",
      "traduction": "Variation de stock (matières premières et approvisionnement) Montant année N"
  },
  {
      "code": "240.2",
      "traduction": "Variation de stock (matières premières et approvisionnement) Montant année N-1"
  },
  {
      "code": "24B.1",
      "traduction": "(dont crédit bail mobilier)* Montant année N"
  },
  {
      "code": "24B.2",
      "traduction": "(dont crédit bail mobilier)* Montant année N-1"
  },
  {
      "code": "24A.1",
      "traduction": "(dont crédit bail immobilier)* Montant année N"
  },
  {
      "code": "24A.2",
      "traduction": "(dont crédit bail immobilier)* Montant année N-1"
  },
  {
      "code": "242.1",
      "traduction": "Autres charges externes* Montant année N"
  },
  {
      "code": "242.2",
      "traduction": "Autres charges externes* Montant année N-1"
  },
  {
      "code": "243.1",
      "traduction": "(dont taxe professionnelle) Montant année N"
  },
  {
      "code": "243.2",
      "traduction": "(dont taxe professionnelle) Montant année N-1"
  },
  {
      "code": "244.1",
      "traduction": "Impôts, taxes et versements assimilés Montant année N"
  },
  {
      "code": "244.2",
      "traduction": "Impôts, taxes et versements assimilés Montant année N-1"
  },
  {
      "code": "250.1",
      "traduction": "Rémunérations du personnel Montant année N"
  },
  {
      "code": "250.2",
      "traduction": "Rémunérations du personnel Montant année N-1"
  },
  {
      "code": "252.1",
      "traduction": "Charges sociales Montant année N"
  },
  {
      "code": "252.2",
      "traduction": "Charges sociales Montant année N-1"
  },
  {
      "code": "254.1",
      "traduction": "Dotations aux amortissements Montant année N"
  },
  {
      "code": "254.2",
      "traduction": "Dotations aux amortissements Montant année N-1"
  },
  {
      "code": "256.1",
      "traduction": "Dotations aux provisions Montant année N"
  },
  {
      "code": "256.2",
      "traduction": "Dotations aux provisions Montant année N-1"
  },
  {
      "code": "259.1",
      "traduction": "(dont provisions fiscales pour implantations commerciales à l'étranger) Montant année N"
  },
  {
      "code": "259.2",
      "traduction": "(dont provisions fiscales pour implantations commerciales à l'étranger) Montant année N-1"
  },
  {
      "code": "262.1",
      "traduction": "Autres charges Montant année N"
  },
  {
      "code": "262.2",
      "traduction": "Autres charges Montant année N-1"
  },
  {
      "code": "264.1",
      "traduction": "Total des charges d'exploitation Montant année N"
  },
  {
      "code": "264.2",
      "traduction": "Total des charges d'exploitation Montant année N-1"
  },
  {
      "code": "270.1",
      "traduction": "Résultat d'exploitation Montant année N"
  },
  {
      "code": "270.2",
      "traduction": "Résultat d'exploitation Montant année N-1"
  },
  {
      "code": "280.1",
      "traduction": "Produits financiers Montant année N"
  },
  {
      "code": "280.2",
      "traduction": "Produits financiers Montant année N-1"
  },
  {
      "code": "290.1",
      "traduction": "Produits exceptionnels Montant année N"
  },
  {
      "code": "290.2",
      "traduction": "Produits exceptionnels Montant année N-1"
  },
  {
      "code": "294.1",
      "traduction": "Charges financières Montant année N"
  },
  {
      "code": "294.2",
      "traduction": "Charges financières Montant année N-1"
  },
  {
      "code": "300.1",
      "traduction": "Charges exceptionnelles Montant année N"
  },
  {
      "code": "300.2",
      "traduction": "Charges exceptionnelles Montant année N-1"
  },
  {
      "code": "306.1",
      "traduction": "Impôts sur les bénéfices Montant année N"
  },
  {
      "code": "306.2",
      "traduction": "Impôts sur les bénéfices Montant année N-1"
  },
  {
      "code": "310.1",
      "traduction": "Bénéfice ou perte Montant année N"
  },
  {
      "code": "310.2",
      "traduction": "Bénéfice ou perte Montant année N-1"
  },
  {
      "code": "316.1",
      "traduction": "Rémunération et avantages personnels non déductibles Montant année N"
  },
  {
      "code": "316.2",
      "traduction": "Rémunération et avantages personnels non déductibles Montant année N-1"
  },
  {
      "code": "374.1",
      "traduction": "Montant de la T.V.A. collectée Montant année N"
  },
  {
      "code": "374.2",
      "traduction": "Montant de la T.V.A. collectée Montant année N-1"
  },
  {
      "code": "376.1",
      "traduction": "Effectif moyen du personnel Montant année N"
  },
  {
      "code": "376.2",
      "traduction": "Effectif moyen du personnel Montant année N-1"
  },
  {
      "code": "378.1",
      "traduction": "Montant de la T.V.A. déductible sur biens et services Montant année N"
  },
  {
      "code": "378.2",
      "traduction": "Montant de la T.V.A. déductible sur biens et services Montant année N-1"
  },
  {
      "code": "IMM.1",
      "traduction": "Montant année N"
  },
  {
      "code": "402.1",
      "traduction": "AUGMENTATIONS Immobilisations incorporelles - Fonds commercial  Montant année N"
  },
  {
      "code": "404.1",
      "traduction": "DIMINUTIONS Immobilisations incorporelles - Fonds commercial  Montant année N"
  },
  {
      "code": "412.1",
      "traduction": "AUGMENTATIONS Immobilisations incorporelles - Autres immobilisations  Montant année N"
  },
  {
      "code": "414.1",
      "traduction": "DIMINUTIONS Immobilisations incorporelles - Autres immobilisations incorporelles  Montant année N"
  },
  {
      "code": "422.1",
      "traduction": "AUGMENTATIONS Imm. corporelles - Terrains  Montant année N"
  },
  {
      "code": "432.1",
      "traduction": "AUGMENTATIONS Imm. corporelles - Constructions  Montant année N"
  },
  {
      "code": "442.1",
      "traduction": "AUGMENTATIONS Imm. corporelles - Installations techniques matériel et outillage  Montant année N"
  },
  {
      "code": "452.1",
      "traduction": "AUGMENTATIONS Imm. corporelles - Installations générales, agencements divers  Montant année N"
  },
  {
      "code": "462.1",
      "traduction": "AUGMENTATIONS Imm. corporelles - Matériel de transport  Montant année N"
  },
  {
      "code": "472.1",
      "traduction": "AUGMENTATIONS Imm. corporelles - Autres immobilisations corporelles  Montant année N"
  },
  {
      "code": "482.1",
      "traduction": "AUGMENTATIONS Immobilisations financières  Montant année N"
  },
  {
      "code": "484.1",
      "traduction": "DIMINUTIONS Immobilisations financières  Montant année N"
  },
  {
      "code": "490.1",
      "traduction": "Total Immobilisations (Valeur brute)  Montant année N"
  },
  {
      "code": "492.1",
      "traduction": "Total Immobilisations (Augmentations)  Montant année N"
  },
  {
      "code": "494.1",
      "traduction": "Total Immobilisations (Diminutions)  Montant année N"
  },
  {
      "code": "582.1",
      "traduction": "Total Plus-values, Moins-values (Valeur résiduelle)  Montant année N"
  },
  {
      "code": "584.1",
      "traduction": "Total Plus-values, Moins-values (Prix de cession)  Montant année N"
  },
  {
      "code": "585.1",
      "traduction": "Total Immobilisations - Amortissement - Plus-values, Moins-values (Long terme)  Montant année N"
  },
  {
      "code": "596.1",
      "traduction": "Total Immobilisations - Amortissement - Plus-values, Moins-values (Court terme)  Montant année N"
  },
  {
      "code": "597.1",
      "traduction": "Total Immobilisations - Amortissement - Plus-values, Moins-values (Long terme  Montant année N"
  },
  {
      "code": "599.1",
      "traduction": "Total Immobilisations - Amortissement - Plus-values, Moins-values (Long terme  Montant année N"
  },
  {
      "code": "PRO.1",
      "traduction": "Montant année N"
  },
  {
      "code": "602.1",
      "traduction": "AUGMENTATIONS Provisions réglementées - Amortissements dérogatoires Montant année N"
  },
  {
      "code": "603.1",
      "traduction": "AUGMENTATIONS Provisions réglementées - Dont majorations exceptionnelles de Montant année N"
  },
  {
      "code": "612.1",
      "traduction": "AUGMENTATIONS Provisions réglementées - Autres provisions réglementées Montant année N"
  },
  {
      "code": "622.1",
      "traduction": "AUGMENTATIONS Provisions pour risques et charges Montant année N"
  },
  {
      "code": "632.1",
      "traduction": "AUGMENTATIONS Provisions pour dépréciation - Sur immobilisations Montant année N"
  },
  {
      "code": "642.1",
      "traduction": "AUGMENTATIONS Provisions pour dépréciation - Sur stocks et en cours Montant année N"
  },
  {
      "code": "652.1",
      "traduction": "AUGMENTATIONS Provisions pour dépréciation - Sur clients et comptes rattachés Montant année N"
  },
  {
      "code": "662.1",
      "traduction": "AUGMENTATIONS Provisions pour dépréciation - Autres provisions pour Montant année N"
  },
  {
      "code": "682.1",
      "traduction": "AUGMENTATIONS Total Relevé des provisions Montant année N"
  },
  {
      "code": "604.1",
      "traduction": "DIMINUTIONS Provisions réglementées - Amortissements dérogatoires Montant année N"
  },
  {
      "code": "605.1",
      "traduction": "DIMINUTIONS Provisions réglementées - Dont majorations exceptionnelles de 30% Montant année N"
  },
  {
      "code": "614.1",
      "traduction": "DIMINUTIONS Provisions réglementées - Autres provisions réglementées Montant année N"
  },
  {
      "code": "624.1",
      "traduction": "DIMINUTIONS Provisions pour risques et charges Montant année N"
  },
  {
      "code": "634.1",
      "traduction": "DIMINUTIONS Provisions pour dépréciation - Sur immobilisations Montant année N"
  },
  {
      "code": "644.1",
      "traduction": "DIMINUTIONS Provisions pour dépréciation - Sur stocks et en cours Montant année N"
  },
  {
      "code": "654.1",
      "traduction": "DIMINUTIONS Provisions pour dépréciation - Sur clients et comptes rattachés Montant année N"
  },
  {
      "code": "664.1",
      "traduction": "DIMINUTIONS Provisions pour dépréciation - Autres provisions pour dépréciation Montant année N"
  },
  {
      "code": "684.1",
      "traduction": "DIMINUTIONS Total Relevé des provisions Montant année N"
  },
  {
      "code": "374.1",
      "traduction": "DIVERS Montant de la TVA collectée Montant année N"
  },
  {
      "code": "378.1",
      "traduction": "DIVERS Montant de la TVA déductible sur biens et services (sauf immobilisations) Montant année N"
  },
  {
      "code": "CET.1",
      "traduction": "Montant année N"
  },
  {
      "code": "376.1",
      "traduction": "Effectifs moyens du personnel Montant année N"
  }
]