import { Critere } from "../models/Critere";
/* 
const listCriteresComplet: Critere[] = [
    {
        code: "FJ.3",
        traduction: "Chiffre d'affaires"
    },
    {
        code: "GG.3",
        traduction: "Resultat d'exploitation"
    },
    {
        code: "DI.1",
        traduction: "Resultat Net"
    },
    {
        code: "CO.3",
        traduction: "Total Actif Net"
    },
    {
        code: "BJ.3",
        traduction: "Immobilisations Nettes"
    },
    {
        code: "CJ.3",
        traduction: "Actifs Circulants Nettes"
    },
    {
        code: "DL.1",
        traduction: "Capitaux propres"
    },
    
    {
        code: "EC.1",
        traduction: "Total Dettes"
    },
    {
        code: "YP.1",
        traduction: "Effectif moyen du personnel"
    }
];


export default listCriteresComplet */
export const listCriteresComplet: Critere[] = [
    {
        "code": "siren",
        "traduction": "siren"
    },
    {
        "code": "date_cloture_exercice",
        "traduction": "date_cloture_exercice"
    },
    {
        "code": "code_greffe",
        "traduction": "code_greffe"
    },
    {
        "code": "num_depot",
        "traduction": "num_depot"
    },
    {
        "code": "num_gestion",
        "traduction": "num_gestion"
    },
    {
        "code": "code_activite",
        "traduction": "code_activite"
    },
    {
        "code": "date_cloture_exercice_n_precedent",
        "traduction": "date_cloture_exercice_n_precedent"
    },
    {
        "code": "duree_exercice_n",
        "traduction": "duree_exercice_n"
    },
    {
        "code": "duree_exercice_n_precedent",
        "traduction": "duree_exercice_n_precedent"
    },
    {
        "code": "date_depot",
        "traduction": "date_depot"
    },
    {
        "code": "code_motif",
        "traduction": "code_motif"
    },
    {
        "code": "code_type_bilan",
        "traduction": "code_type_bilan"
    },
    {
        "code": "code_devise",
        "traduction": "code_devise"
    },
    {
        "code": "code_origine_devise",
        "traduction": "code_origine_devise"
    },
    {
        "code": "code_confidentialite",
        "traduction": "code_confidentialite"
    },
    {
        "code": "denomination",
        "traduction": "denomination"
    },
    {
        "code": "adresse",
        "traduction": "adresse"
    },
    {
        "code": "A.1",
        "traduction": "Montant brut année N"
    },
    {
        "code": "A.2",
        "traduction": "Amortissement année N"
    },
    {
        "code": "A.3",
        "traduction": "Net année N"
    },
    {
        "code": "A.4",
        "traduction": "Net année N-1"
    },
    {
        "code": "AA.1",
        "traduction": "Capital souscrit non appelé Montant brut année N"
    },
    {
        "code": "AA.2",
        "traduction": "Capital souscrit non appelé Amortissement année N"
    },
    {
        "code": "AA.3",
        "traduction": "Capital souscrit non appelé Net année N"
    },
    {
        "code": "AA.4",
        "traduction": "Capital souscrit non appelé Net année N-1"
    },
    {
        "code": "AB.1",
        "traduction": "Frais d’établissement Montant brut année N"
    },
    {
        "code": "AB.2",
        "traduction": "Frais d’établissement Amortissement année N"
    },
    {
        "code": "AB.3",
        "traduction": "Frais d’établissement Net année N"
    },
    {
        "code": "AB.4",
        "traduction": "Frais d’établissement Net année N-1"
    },
    {
        "code": "CX.1",
        "traduction": "Frais de développement ou de recherche et développement Montant brut année N"
    },
    {
        "code": "CX.2",
        "traduction": "Frais de développement ou de recherche et développement Amortissement année N"
    },
    {
        "code": "CX.3",
        "traduction": "Frais de développement ou de recherche et développement Net année N"
    },
    {
        "code": "CX.4",
        "traduction": "Frais de développement ou de recherche et développement Net année N-1"
    },
    {
        "code": "AF.1",
        "traduction": "Concessions, brevets et droits similaires Montant brut année N"
    },
    {
        "code": "AF.2",
        "traduction": "Concessions, brevets et droits similaires Amortissement année N"
    },
    {
        "code": "AF.3",
        "traduction": "Concessions, brevets et droits similaires Net année N"
    },
    {
        "code": "AF.4",
        "traduction": "Concessions, brevets et droits similaires Net année N-1"
    },
    {
        "code": "AH.1",
        "traduction": "Fonds commercial Montant brut année N"
    },
    {
        "code": "AH.2",
        "traduction": "Fonds commercial Amortissement année N"
    },
    {
        "code": "AH.3",
        "traduction": "Fonds commercial Net année N"
    },
    {
        "code": "AH.4",
        "traduction": "Fonds commercial Net année N-1"
    },
    {
        "code": "AJ.1",
        "traduction": "Autres immobilisations incorporelles Montant brut année N"
    },
    {
        "code": "AJ.2",
        "traduction": "Autres immobilisations incorporelles Amortissement année N"
    },
    {
        "code": "AJ.3",
        "traduction": "Autres immobilisations incorporelles Net année N"
    },
    {
        "code": "AJ.4",
        "traduction": "Autres immobilisations incorporelles Net année N-1"
    },
    {
        "code": "AL.1",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Montant brut année N"
    },
    {
        "code": "AL.2",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Amortissement année N"
    },
    {
        "code": "AL.3",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Net année N"
    },
    {
        "code": "AL.4",
        "traduction": "Avances et acomptes sur immobilisations incorporelles. Net année N-1"
    },
    {
        "code": "AN.1",
        "traduction": "Terrains Montant brut année N"
    },
    {
        "code": "AN.2",
        "traduction": "Terrains Amortissement année N"
    },
    {
        "code": "AN.3",
        "traduction": "Terrains Net année N"
    },
    {
        "code": "AN.4",
        "traduction": "Terrains Net année N-1"
    },
    {
        "code": "AP.1",
        "traduction": "Constructions Montant brut année N"
    },
    {
        "code": "AP.2",
        "traduction": "Constructions Amortissement année N"
    },
    {
        "code": "AP.3",
        "traduction": "Constructions Net année N"
    },
    {
        "code": "AP.4",
        "traduction": "Constructions Net année N-1"
    },
    {
        "code": "AR.1",
        "traduction": "Installations techniques, matériel et outillage industriels Montant brut année N"
    },
    {
        "code": "AR.2",
        "traduction": "Installations techniques, matériel et outillage industriels Amortissement année N"
    },
    {
        "code": "AR.3",
        "traduction": "Installations techniques, matériel et outillage industriels Net année N"
    },
    {
        "code": "AR.4",
        "traduction": "Installations techniques, matériel et outillage industriels Net année N-1"
    },
    {
        "code": "AT.1",
        "traduction": "Autres immobilisations corporelles Montant brut année N"
    },
    {
        "code": "AT.2",
        "traduction": "Autres immobilisations corporelles Amortissement année N"
    },
    {
        "code": "AT.3",
        "traduction": "Autres immobilisations corporelles Net année N"
    },
    {
        "code": "AT.4",
        "traduction": "Autres immobilisations corporelles Net année N-1"
    },
    {
        "code": "AV.1",
        "traduction": "Immobilisations en cours Montant brut année N"
    },
    {
        "code": "AV.2",
        "traduction": "Immobilisations en cours Amortissement année N"
    },
    {
        "code": "AV.3",
        "traduction": "Immobilisations en cours Net année N"
    },
    {
        "code": "AV.4",
        "traduction": "Immobilisations en cours Net année N-1"
    },
    {
        "code": "AX.1",
        "traduction": "Avances et acomptes Montant brut année N"
    },
    {
        "code": "AX.2",
        "traduction": "Avances et acomptes Amortissement année N"
    },
    {
        "code": "AX.3",
        "traduction": "Avances et acomptes Net année N"
    },
    {
        "code": "AX.4",
        "traduction": "Avances et acomptes Net année N-1"
    },
    {
        "code": "CS.1",
        "traduction": "Participations évaluées - mise en équivalence Montant brut année N"
    },
    {
        "code": "CS.2",
        "traduction": "Participations évaluées - mise en équivalence Amortissement année N"
    },
    {
        "code": "CS.3",
        "traduction": "Participations évaluées - mise en équivalence Net année N"
    },
    {
        "code": "CS.4",
        "traduction": "Participations évaluées - mise en équivalence Net année N-1"
    },
    {
        "code": "CU.1",
        "traduction": "Autres participations Montant brut année N"
    },
    {
        "code": "CU.2",
        "traduction": "Autres participations Amortissement année N"
    },
    {
        "code": "CU.3",
        "traduction": "Autres participations Net année N"
    },
    {
        "code": "CU.4",
        "traduction": "Autres participations Net année N-1"
    },
    {
        "code": "BB.1",
        "traduction": "Créances rattachées à des participations Montant brut année N"
    },
    {
        "code": "BB.2",
        "traduction": "Créances rattachées à des participations Amortissement année N"
    },
    {
        "code": "BB.3",
        "traduction": "Créances rattachées à des participations Net année N"
    },
    {
        "code": "BB.4",
        "traduction": "Créances rattachées à des participations Net année N-1"
    },
    {
        "code": "BD.1",
        "traduction": "Autres titres immobilisés Montant brut année N"
    },
    {
        "code": "BD.2",
        "traduction": "Autres titres immobilisés Amortissement année N"
    },
    {
        "code": "BD.3",
        "traduction": "Autres titres immobilisés Net année N"
    },
    {
        "code": "BD.4",
        "traduction": "Autres titres immobilisés Net année N-1"
    },
    {
        "code": "BF.1",
        "traduction": "Prêts Montant brut année N"
    },
    {
        "code": "BF.2",
        "traduction": "Prêts Amortissement année N"
    },
    {
        "code": "BF.3",
        "traduction": "Prêts Net année N"
    },
    {
        "code": "BF.4",
        "traduction": "Prêts Net année N-1"
    },
    {
        "code": "BH.1",
        "traduction": "Autres immobilisations financières Montant brut année N"
    },
    {
        "code": "BH.2",
        "traduction": "Autres immobilisations financières Amortissement année N"
    },
    {
        "code": "BH.3",
        "traduction": "Autres immobilisations financières Net année N"
    },
    {
        "code": "BH.4",
        "traduction": "Autres immobilisations financières Net année N-1"
    },
    {
        "code": "BJ.1",
        "traduction": "TOTAL (I) Montant brut année N"
    },
    {
        "code": "BJ.2",
        "traduction": "TOTAL (I) Amortissement année N"
    },
    {
        "code": "BJ.3",
        "traduction": "TOTAL (I) Net année N"
    },
    {
        "code": "BJ.4",
        "traduction": "TOTAL (I) Net année N-1"
    },
    {
        "code": "BL.1",
        "traduction": "Matières premières, approvisionnements Montant brut année N"
    },
    {
        "code": "BL.2",
        "traduction": "Matières premières, approvisionnements Amortissement année N"
    },
    {
        "code": "BL.3",
        "traduction": "Matières premières, approvisionnements Net année N"
    },
    {
        "code": "BL.4",
        "traduction": "Matières premières, approvisionnements Net année N-1"
    },
    {
        "code": "BN.1",
        "traduction": "En cours de production de biens Montant brut année N"
    },
    {
        "code": "BN.2",
        "traduction": "En cours de production de biens Amortissement année N"
    },
    {
        "code": "BN.3",
        "traduction": "En cours de production de biens Net année N"
    },
    {
        "code": "BN.4",
        "traduction": "En cours de production de biens Net année N-1"
    },
    {
        "code": "BP.1",
        "traduction": "En cours de production de services Montant brut année N"
    },
    {
        "code": "BP.2",
        "traduction": "En cours de production de services Amortissement année N"
    },
    {
        "code": "BP.3",
        "traduction": "En cours de production de services Net année N"
    },
    {
        "code": "BP.4",
        "traduction": "En cours de production de services Net année N-1"
    },
    {
        "code": "BR.1",
        "traduction": "Produits intermédiaires et finis Montant brut année N"
    },
    {
        "code": "BR.2",
        "traduction": "Produits intermédiaires et finis Amortissement année N"
    },
    {
        "code": "BR.3",
        "traduction": "Produits intermédiaires et finis Net année N"
    },
    {
        "code": "BR.4",
        "traduction": "Produits intermédiaires et finis Net année N-1"
    },
    {
        "code": "BT.1",
        "traduction": "Marchandises Montant brut année N"
    },
    {
        "code": "BT.2",
        "traduction": "Marchandises Amortissement année N"
    },
    {
        "code": "BT.3",
        "traduction": "Marchandises Net année N"
    },
    {
        "code": "BT.4",
        "traduction": "Marchandises Net année N-1"
    },
    {
        "code": "BV.1",
        "traduction": "Avances et acomptes versés sur commandes Montant brut année N"
    },
    {
        "code": "BV.2",
        "traduction": "Avances et acomptes versés sur commandes Amortissement année N"
    },
    {
        "code": "BV.3",
        "traduction": "Avances et acomptes versés sur commandes Net année N"
    },
    {
        "code": "BV.4",
        "traduction": "Avances et acomptes versés sur commandes Net année N-1"
    },
    {
        "code": "BX.1",
        "traduction": "Clients et comptes rattachés Montant brut année N"
    },
    {
        "code": "BX.2",
        "traduction": "Clients et comptes rattachés Amortissement année N"
    },
    {
        "code": "BX.3",
        "traduction": "Clients et comptes rattachés Net année N"
    },
    {
        "code": "BX.4",
        "traduction": "Clients et comptes rattachés Net année N-1"
    },
    {
        "code": "BZ.1",
        "traduction": "Autres créances Montant brut année N"
    },
    {
        "code": "BZ.2",
        "traduction": "Autres créances Amortissement année N"
    },
    {
        "code": "BZ.3",
        "traduction": "Autres créances Net année N"
    },
    {
        "code": "BZ.4",
        "traduction": "Autres créances Net année N-1"
    },
    {
        "code": "CB.1",
        "traduction": "Capital souscrit et appelé, non versé Montant brut année N"
    },
    {
        "code": "CB.2",
        "traduction": "Capital souscrit et appelé, non versé Amortissement année N"
    },
    {
        "code": "CB.3",
        "traduction": "Capital souscrit et appelé, non versé Net année N"
    },
    {
        "code": "CB.4",
        "traduction": "Capital souscrit et appelé, non versé Net année N-1"
    },
    {
        "code": "CD.1",
        "traduction": "Valeurs mobilières de placement Montant brut année N"
    },
    {
        "code": "CD.2",
        "traduction": "Valeurs mobilières de placement Amortissement année N"
    },
    {
        "code": "CD.3",
        "traduction": "Valeurs mobilières de placement Net année N"
    },
    {
        "code": "CD.4",
        "traduction": "Valeurs mobilières de placement Net année N-1"
    },
    {
        "code": "CF.1",
        "traduction": "Disponibilités Montant brut année N"
    },
    {
        "code": "CF.2",
        "traduction": "Disponibilités Amortissement année N"
    },
    {
        "code": "CF.3",
        "traduction": "Disponibilités Net année N"
    },
    {
        "code": "CF.4",
        "traduction": "Disponibilités Net année N-1"
    },
    {
        "code": "CH.1",
        "traduction": "Charges constatées d’avance Montant brut année N"
    },
    {
        "code": "CH.2",
        "traduction": "Charges constatées d’avance Amortissement année N"
    },
    {
        "code": "CH.3",
        "traduction": "Charges constatées d’avance Net année N"
    },
    {
        "code": "CH.4",
        "traduction": "Charges constatées d’avance Net année N-1"
    },
    {
        "code": "CJ.1",
        "traduction": "TOTAL (II) Montant brut année N"
    },
    {
        "code": "CJ.2",
        "traduction": "TOTAL (II) Amortissement année N"
    },
    {
        "code": "CJ.3",
        "traduction": "TOTAL (II) Net année N"
    },
    {
        "code": "CJ.4",
        "traduction": "TOTAL (II) Net année N-1"
    },
    {
        "code": "CW.1",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Montant brut année N"
    },
    {
        "code": "CW.2",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Amortissement année N"
    },
    {
        "code": "CW.3",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Net année N"
    },
    {
        "code": "CW.4",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Net année N-1"
    },
    {
        "code": "CM.1",
        "traduction": "Primes de remboursement des obligations (IV) Montant brut année N"
    },
    {
        "code": "CM.2",
        "traduction": "Primes de remboursement des obligations (IV) Amortissement année N"
    },
    {
        "code": "CM.3",
        "traduction": "Primes de remboursement des obligations (IV) Net année N"
    },
    {
        "code": "CM.4",
        "traduction": "Primes de remboursement des obligations (IV) Net année N-1"
    },
    {
        "code": "CN.1",
        "traduction": "Ecarts de conversion actif (V) Montant brut année N"
    },
    {
        "code": "CN.2",
        "traduction": "Ecarts de conversion actif (V) Amortissement année N"
    },
    {
        "code": "CN.3",
        "traduction": "Ecarts de conversion actif (V) Net année N"
    },
    {
        "code": "CN.4",
        "traduction": "Ecarts de conversion actif (V) Net année N-1"
    },
    {
        "code": "CO.1",
        "traduction": "TOTAL GENERAL (0 à V) Montant brut année N"
    },
    {
        "code": "CO.2",
        "traduction": "TOTAL GENERAL (0 à V) Amortissement année N"
    },
    {
        "code": "CO.3",
        "traduction": "TOTAL GENERAL (0 à V) Net année N"
    },
    {
        "code": "CO.4",
        "traduction": "TOTAL GENERAL (0 à V) Net année N-1"
    },
    {
        "code": "CP.1",
        "traduction": "Parts à moins d’un an Montant brut année N"
    },
    {
        "code": "CP.2",
        "traduction": "Parts à moins d’un an Amortissement année N"
    },
    {
        "code": "CP.3",
        "traduction": "Parts à moins d’un an Net année N"
    },
    {
        "code": "CP.4",
        "traduction": "Parts à moins d’un an Net année N-1"
    },
    {
        "code": "CR.1",
        "traduction": "Parts à plus d’un an Montant brut année N"
    },
    {
        "code": "CR.2",
        "traduction": "Parts à plus d’un an Amortissement année N"
    },
    {
        "code": "CR.3",
        "traduction": "Parts à plus d’un an Net année N"
    },
    {
        "code": "CR.4",
        "traduction": "Parts à plus d’un an Net année N-1"
    },
    {
        "code": "P.1",
        "traduction": "Montant année N"
    },
    {
        "code": "P.2",
        "traduction": "Montant année N-1"
    },
    {
        "code": "P.3",
        "traduction": ""
    },
    {
        "code": "P.4",
        "traduction": ""
    },
    {
        "code": "DA.1",
        "traduction": "Capital social ou individuel Montant année N"
    },
    {
        "code": "DA.2",
        "traduction": "Capital social ou individuel Montant année N-1"
    },
    {
        "code": "DA.3",
        "traduction": ""
    },
    {
        "code": "DA.4",
        "traduction": ""
    },
    {
        "code": "DB.1",
        "traduction": "Primes d’émission, de fusion, d’apport, ... Montant année N"
    },
    {
        "code": "DB.2",
        "traduction": "Primes d’émission, de fusion, d’apport, ... Montant année N-1"
    },
    {
        "code": "DB.3",
        "traduction": ""
    },
    {
        "code": "DB.4",
        "traduction": ""
    },
    {
        "code": "EK.1",
        "traduction": "(dont écart d’équivalence) Montant année N"
    },
    {
        "code": "EK.2",
        "traduction": "(dont écart d’équivalence) Montant année N-1"
    },
    {
        "code": "EK.3",
        "traduction": ""
    },
    {
        "code": "EK.4",
        "traduction": ""
    },
    {
        "code": "DC.1",
        "traduction": "Ecarts de réévaluation Montant année N"
    },
    {
        "code": "DC.2",
        "traduction": "Ecarts de réévaluation Montant année N-1"
    },
    {
        "code": "DC.3",
        "traduction": ""
    },
    {
        "code": "DC.4",
        "traduction": ""
    },
    {
        "code": "DD.1",
        "traduction": "Réserve légale (1) Montant année N"
    },
    {
        "code": "DD.2",
        "traduction": "Réserve légale (1) Montant année N-1"
    },
    {
        "code": "DD.3",
        "traduction": ""
    },
    {
        "code": "DD.4",
        "traduction": ""
    },
    {
        "code": "DE.1",
        "traduction": "Réserves statutaires ou contractuelles Montant année N"
    },
    {
        "code": "DE.2",
        "traduction": "Réserves statutaires ou contractuelles Montant année N-1"
    },
    {
        "code": "DE.3",
        "traduction": ""
    },
    {
        "code": "DE.4",
        "traduction": ""
    },
    {
        "code": "B1.1",
        "traduction": "(dont réserve spéciale des provisions pour fluctuation des cours) Montant année N"
    },
    {
        "code": "B1.2",
        "traduction": "(dont réserve spéciale des provisions pour fluctuation des cours) Montant année N-1"
    },
    {
        "code": "B1.3",
        "traduction": ""
    },
    {
        "code": "B1.4",
        "traduction": ""
    },
    {
        "code": "DF.1",
        "traduction": "Réserves réglementées (1) Montant année N"
    },
    {
        "code": "DF.2",
        "traduction": "Réserves réglementées (1) Montant année N-1"
    },
    {
        "code": "DF.3",
        "traduction": ""
    },
    {
        "code": "DF.4",
        "traduction": ""
    },
    {
        "code": "EJ.1",
        "traduction": "(dont réserve relative à l’achat d’oeuvres originales d’artistes vivants) Montant année N"
    },
    {
        "code": "EJ.2",
        "traduction": "(dont réserve relative à l’achat d’oeuvres originales d’artistes vivants) Montant année N-1"
    },
    {
        "code": "EJ.3",
        "traduction": ""
    },
    {
        "code": "EJ.4",
        "traduction": ""
    },
    {
        "code": "DG.1",
        "traduction": "Autres réserves Montant année N"
    },
    {
        "code": "DG.2",
        "traduction": "Autres réserves Montant année N-1"
    },
    {
        "code": "DG.3",
        "traduction": ""
    },
    {
        "code": "DG.4",
        "traduction": ""
    },
    {
        "code": "DH.1",
        "traduction": "Report à nouveau Montant année N"
    },
    {
        "code": "DH.2",
        "traduction": "Report à nouveau Montant année N-1"
    },
    {
        "code": "DH.3",
        "traduction": ""
    },
    {
        "code": "DH.4",
        "traduction": ""
    },
    {
        "code": "DI.1",
        "traduction": "RESULTAT DE L’EXERCICE (bénéfice ou perte) Montant année N"
    },
    {
        "code": "DI.2",
        "traduction": "RESULTAT DE L’EXERCICE (bénéfice ou perte) Montant année N-1"
    },
    {
        "code": "DI.3",
        "traduction": ""
    },
    {
        "code": "DI.4",
        "traduction": ""
    },
    {
        "code": "DJ.1",
        "traduction": "Subventions d’investissement Montant année N"
    },
    {
        "code": "DJ.2",
        "traduction": "Subventions d’investissement Montant année N-1"
    },
    {
        "code": "DJ.3",
        "traduction": ""
    },
    {
        "code": "DJ.4",
        "traduction": ""
    },
    {
        "code": "DK.1",
        "traduction": "Provisions réglementées Montant année N"
    },
    {
        "code": "DK.2",
        "traduction": "Provisions réglementées Montant année N-1"
    },
    {
        "code": "DK.3",
        "traduction": ""
    },
    {
        "code": "DK.4",
        "traduction": ""
    },
    {
        "code": "DL.1",
        "traduction": "TOTAL (I) Montant année N"
    },
    {
        "code": "DL.2",
        "traduction": "TOTAL (I) Montant année N-1"
    },
    {
        "code": "DL.3",
        "traduction": ""
    },
    {
        "code": "DL.4",
        "traduction": ""
    },
    {
        "code": "DM.1",
        "traduction": "Produit des émissions de titres participatifs Montant année N"
    },
    {
        "code": "DM.2",
        "traduction": "Produit des émissions de titres participatifs Montant année N-1"
    },
    {
        "code": "DM.3",
        "traduction": ""
    },
    {
        "code": "DM.4",
        "traduction": ""
    },
    {
        "code": "DN.1",
        "traduction": "Avances conditionnées Montant année N"
    },
    {
        "code": "DN.2",
        "traduction": "Avances conditionnées Montant année N-1"
    },
    {
        "code": "DN.3",
        "traduction": ""
    },
    {
        "code": "DN.4",
        "traduction": ""
    },
    {
        "code": "DO.1",
        "traduction": "TOTAL (II) Montant année N"
    },
    {
        "code": "DO.2",
        "traduction": "TOTAL (II) Montant année N-1"
    },
    {
        "code": "DO.3",
        "traduction": ""
    },
    {
        "code": "DO.4",
        "traduction": ""
    },
    {
        "code": "DP.1",
        "traduction": "Provisions pour risques Montant année N"
    },
    {
        "code": "DP.2",
        "traduction": "Provisions pour risques Montant année N-1"
    },
    {
        "code": "DP.3",
        "traduction": ""
    },
    {
        "code": "DP.4",
        "traduction": ""
    },
    {
        "code": "DQ.1",
        "traduction": "Provisions pour charges Montant année N"
    },
    {
        "code": "DQ.2",
        "traduction": "Provisions pour charges Montant année N-1"
    },
    {
        "code": "DQ.3",
        "traduction": ""
    },
    {
        "code": "DQ.4",
        "traduction": ""
    },
    {
        "code": "DR.1",
        "traduction": "TOTAL (III) Montant année N"
    },
    {
        "code": "DR.2",
        "traduction": "TOTAL (III) Montant année N-1"
    },
    {
        "code": "DR.3",
        "traduction": ""
    },
    {
        "code": "DR.4",
        "traduction": ""
    },
    {
        "code": "DS.1",
        "traduction": "Emprunts obligataires convertibles Montant année N"
    },
    {
        "code": "DS.2",
        "traduction": "Emprunts obligataires convertibles Montant année N-1"
    },
    {
        "code": "DS.3",
        "traduction": ""
    },
    {
        "code": "DS.4",
        "traduction": ""
    },
    {
        "code": "DT.1",
        "traduction": "Autres emprunts obligataires Montant année N"
    },
    {
        "code": "DT.2",
        "traduction": "Autres emprunts obligataires Montant année N-1"
    },
    {
        "code": "DT.3",
        "traduction": ""
    },
    {
        "code": "DT.4",
        "traduction": ""
    },
    {
        "code": "DU.1",
        "traduction": "Emprunts et dettes auprès des établissements de crédit (3) Montant année N"
    },
    {
        "code": "DU.2",
        "traduction": "Emprunts et dettes auprès des établissements de crédit (3) Montant année N-1"
    },
    {
        "code": "DU.3",
        "traduction": ""
    },
    {
        "code": "DU.4",
        "traduction": ""
    },
    {
        "code": "DV.1",
        "traduction": "Emprunts et dettes financières divers (4) Montant année N"
    },
    {
        "code": "DV.2",
        "traduction": "Emprunts et dettes financières divers (4) Montant année N-1"
    },
    {
        "code": "DV.3",
        "traduction": ""
    },
    {
        "code": "DV.4",
        "traduction": ""
    },
    {
        "code": "DW.1",
        "traduction": "Avances et acomptes reçus sur commandes en cours Montant année N"
    },
    {
        "code": "DW.2",
        "traduction": "Avances et acomptes reçus sur commandes en cours Montant année N-1"
    },
    {
        "code": "DW.3",
        "traduction": ""
    },
    {
        "code": "DW.4",
        "traduction": ""
    },
    {
        "code": "DX.1",
        "traduction": "Dettes fournisseurs et comptes rattachés Montant année N"
    },
    {
        "code": "DX.2",
        "traduction": "Dettes fournisseurs et comptes rattachés Montant année N-1"
    },
    {
        "code": "DX.3",
        "traduction": ""
    },
    {
        "code": "DX.4",
        "traduction": ""
    },
    {
        "code": "DY.1",
        "traduction": "Dettes fiscales et sociales Montant année N"
    },
    {
        "code": "DY.2",
        "traduction": "Dettes fiscales et sociales Montant année N-1"
    },
    {
        "code": "DY.3",
        "traduction": ""
    },
    {
        "code": "DY.4",
        "traduction": ""
    },
    {
        "code": "DZ.1",
        "traduction": "Dettes sur immobilisations et comptes rattachés Montant année N"
    },
    {
        "code": "DZ.2",
        "traduction": "Dettes sur immobilisations et comptes rattachés Montant année N-1"
    },
    {
        "code": "DZ.3",
        "traduction": ""
    },
    {
        "code": "DZ.4",
        "traduction": ""
    },
    {
        "code": "EA.1",
        "traduction": "Autres dettes Montant année N"
    },
    {
        "code": "EA.2",
        "traduction": "Autres dettes Montant année N-1"
    },
    {
        "code": "EA.3",
        "traduction": ""
    },
    {
        "code": "EA.4",
        "traduction": ""
    },
    {
        "code": "EB.1",
        "traduction": "Produits constatés d’avance (2) Montant année N"
    },
    {
        "code": "EB.2",
        "traduction": "Produits constatés d’avance (2) Montant année N-1"
    },
    {
        "code": "EB.3",
        "traduction": ""
    },
    {
        "code": "EB.4",
        "traduction": ""
    },
    {
        "code": "EC.1",
        "traduction": "TOTAL (IV) Montant année N"
    },
    {
        "code": "EC.2",
        "traduction": "TOTAL (IV) Montant année N-1"
    },
    {
        "code": "EC.3",
        "traduction": ""
    },
    {
        "code": "EC.4",
        "traduction": ""
    },
    {
        "code": "ED.1",
        "traduction": "(V) Montant année N"
    },
    {
        "code": "ED.2",
        "traduction": "(V) Montant année N-1"
    },
    {
        "code": "ED.3",
        "traduction": ""
    },
    {
        "code": "ED.4",
        "traduction": ""
    },
    {
        "code": "EE.1",
        "traduction": "TOTAL GENERAL (I à V) Montant année N"
    },
    {
        "code": "EE.2",
        "traduction": "TOTAL GENERAL (I à V) Montant année N-1"
    },
    {
        "code": "EE.3",
        "traduction": ""
    },
    {
        "code": "EE.4",
        "traduction": ""
    },
    {
        "code": "EF.1",
        "traduction": "Dont réserve réglementée des plus-values à long terme Montant année N"
    },
    {
        "code": "EF.2",
        "traduction": "Dont réserve réglementée des plus-values à long terme Montant année N-1"
    },
    {
        "code": "EF.3",
        "traduction": ""
    },
    {
        "code": "EF.4",
        "traduction": ""
    },
    {
        "code": "EG.1",
        "traduction": "Dettes et produits constatés d’avance à moins d’un an Montant année N"
    },
    {
        "code": "EG.2",
        "traduction": "Dettes et produits constatés d’avance à moins d’un an Montant année N-1"
    },
    {
        "code": "EG.3",
        "traduction": ""
    },
    {
        "code": "EG.4",
        "traduction": ""
    },
    {
        "code": "EH.1",
        "traduction": "Dont concours bancaires courants, et soldes créditeurs de banques et C.C.P. Montant année N"
    },
    {
        "code": "EH.2",
        "traduction": "Dont concours bancaires courants, et soldes créditeurs de banques et C.C.P. Montant année N-1"
    },
    {
        "code": "EH.3",
        "traduction": ""
    },
    {
        "code": "EH.4",
        "traduction": ""
    },
    {
        "code": "EI.1",
        "traduction": "Dont emprunts participatifs Montant année N"
    },
    {
        "code": "EI.2",
        "traduction": "Dont emprunts participatifs Montant année N-1"
    },
    {
        "code": "EI.3",
        "traduction": ""
    },
    {
        "code": "EI.4",
        "traduction": ""
    },
    {
        "code": "CDR.1",
        "traduction": "Montant France année N"
    },
    {
        "code": "CDR.2",
        "traduction": "Montant Export année N"
    },
    {
        "code": "CDR.3",
        "traduction": "Total année N"
    },
    {
        "code": "CDR.4",
        "traduction": "Total année N-1"
    },
    {
        "code": "FA.1",
        "traduction": "Ventes de marchandises Montant France année N"
    },
    {
        "code": "FA.2",
        "traduction": "Ventes de marchandises Montant Export année N"
    },
    {
        "code": "FA.3",
        "traduction": "Ventes de marchandises Total année N"
    },
    {
        "code": "FA.4",
        "traduction": "Ventes de marchandises Total année N-1"
    },
    {
        "code": "FD.1",
        "traduction": "Production vendue biens Montant France année N"
    },
    {
        "code": "FD.2",
        "traduction": "Production vendue biens Montant Export année N"
    },
    {
        "code": "FD.3",
        "traduction": "Production vendue biens Total année N"
    },
    {
        "code": "FD.4",
        "traduction": "Production vendue biens Total année N-1"
    },
    {
        "code": "FG.1",
        "traduction": "Production vendue services Montant France année N"
    },
    {
        "code": "FG.2",
        "traduction": "Production vendue services Montant Export année N"
    },
    {
        "code": "FG.3",
        "traduction": "Production vendue services Total année N"
    },
    {
        "code": "FG.4",
        "traduction": "Production vendue services Total année N-1"
    },
    {
        "code": "FJ.1",
        "traduction": "Chiffres d’affaires nets Montant France année N"
    },
    {
        "code": "FJ.2",
        "traduction": "Chiffres d’affaires nets Montant Export année N"
    },
    {
        "code": "FJ.3",
        "traduction": "Chiffres d’affaires nets Total année N"
    },
    {
        "code": "FJ.4",
        "traduction": "Chiffres d’affaires nets Total année N-1"
    },
    {
        "code": "FM.1",
        "traduction": "Production stockée Montant France année N"
    },
    {
        "code": "FM.2",
        "traduction": "Production stockée Montant Export année N"
    },
    {
        "code": "FM.3",
        "traduction": "Production stockée Total année N"
    },
    {
        "code": "FM.4",
        "traduction": "Production stockée Total année N-1"
    },
    {
        "code": "FN.1",
        "traduction": "Production immobilisée Montant France année N"
    },
    {
        "code": "FN.2",
        "traduction": "Production immobilisée Montant Export année N"
    },
    {
        "code": "FN.3",
        "traduction": "Production immobilisée Total année N"
    },
    {
        "code": "FN.4",
        "traduction": "Production immobilisée Total année N-1"
    },
    {
        "code": "FO.1",
        "traduction": "Subventions d’exploitation Montant France année N"
    },
    {
        "code": "FO.2",
        "traduction": "Subventions d’exploitation Montant Export année N"
    },
    {
        "code": "FO.3",
        "traduction": "Subventions d’exploitation Total année N"
    },
    {
        "code": "FO.4",
        "traduction": "Subventions d’exploitation Total année N-1"
    },
    {
        "code": "FP.1",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Montant France année N"
    },
    {
        "code": "FP.2",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Montant Export année N"
    },
    {
        "code": "FP.3",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Total année N"
    },
    {
        "code": "FP.4",
        "traduction": "Reprises sur amortissements et provisions, transfert de charges Total année N-1"
    },
    {
        "code": "FQ.1",
        "traduction": "Autres produits Montant France année N"
    },
    {
        "code": "FQ.2",
        "traduction": "Autres produits Montant Export année N"
    },
    {
        "code": "FQ.3",
        "traduction": "Autres produits Total année N"
    },
    {
        "code": "FQ.4",
        "traduction": "Autres produits Total année N-1"
    },
    {
        "code": "FR.1",
        "traduction": "Total des produits d’exploitation (I) Montant France année N"
    },
    {
        "code": "FR.2",
        "traduction": "Total des produits d’exploitation (I) Montant Export année N"
    },
    {
        "code": "FR.3",
        "traduction": "Total des produits d’exploitation (I) Total année N"
    },
    {
        "code": "FR.4",
        "traduction": "Total des produits d’exploitation (I) Total année N-1"
    },
    {
        "code": "FS.1",
        "traduction": "Achats de marchandises (y compris droits de douane) Montant France année N"
    },
    {
        "code": "FS.2",
        "traduction": "Achats de marchandises (y compris droits de douane) Montant Export année N"
    },
    {
        "code": "FS.3",
        "traduction": "Achats de marchandises (y compris droits de douane) Total année N"
    },
    {
        "code": "FS.4",
        "traduction": "Achats de marchandises (y compris droits de douane) Total année N-1"
    },
    {
        "code": "FT.1",
        "traduction": "Variation de stock (marchandises) Montant France année N"
    },
    {
        "code": "FT.2",
        "traduction": "Variation de stock (marchandises) Montant Export année N"
    },
    {
        "code": "FT.3",
        "traduction": "Variation de stock (marchandises) Total année N"
    },
    {
        "code": "FT.4",
        "traduction": "Variation de stock (marchandises) Total année N-1"
    },
    {
        "code": "FU.1",
        "traduction": "Achats de matières premières et autres approvisionnements Montant France année N"
    },
    {
        "code": "FU.2",
        "traduction": "Achats de matières premières et autres approvisionnements Montant Export année N"
    },
    {
        "code": "FU.3",
        "traduction": "Achats de matières premières et autres approvisionnements Total année N"
    },
    {
        "code": "FU.4",
        "traduction": "Achats de matières premières et autres approvisionnements Total année N-1"
    },
    {
        "code": "FV.1",
        "traduction": "Variation de stock (matières premières et approvisionnements) Montant France année N"
    },
    {
        "code": "FV.2",
        "traduction": "Variation de stock (matières premières et approvisionnements) Montant Export année N"
    },
    {
        "code": "FV.3",
        "traduction": "Variation de stock (matières premières et approvisionnements) Total année N"
    },
    {
        "code": "FV.4",
        "traduction": "Variation de stock (matières premières et approvisionnements) Total année N-1"
    },
    {
        "code": "FW.1",
        "traduction": "Autres achats et charges externes Montant France année N"
    },
    {
        "code": "FW.2",
        "traduction": "Autres achats et charges externes Montant Export année N"
    },
    {
        "code": "FW.3",
        "traduction": "Autres achats et charges externes Total année N"
    },
    {
        "code": "FW.4",
        "traduction": "Autres achats et charges externes Total année N-1"
    },
    {
        "code": "FX.1",
        "traduction": "Impôts, taxes et versements assimilés Montant France année N"
    },
    {
        "code": "FX.2",
        "traduction": "Impôts, taxes et versements assimilés Montant Export année N"
    },
    {
        "code": "FX.3",
        "traduction": "Impôts, taxes et versements assimilés Total année N"
    },
    {
        "code": "FX.4",
        "traduction": "Impôts, taxes et versements assimilés Total année N-1"
    },
    {
        "code": "FY.1",
        "traduction": "Salaires et traitements Montant France année N"
    },
    {
        "code": "FY.2",
        "traduction": "Salaires et traitements Montant Export année N"
    },
    {
        "code": "FY.3",
        "traduction": "Salaires et traitements Total année N"
    },
    {
        "code": "FY.4",
        "traduction": "Salaires et traitements Total année N-1"
    },
    {
        "code": "FZ.1",
        "traduction": "Charges sociales Montant France année N"
    },
    {
        "code": "FZ.2",
        "traduction": "Charges sociales Montant Export année N"
    },
    {
        "code": "FZ.3",
        "traduction": "Charges sociales Total année N"
    },
    {
        "code": "FZ.4",
        "traduction": "Charges sociales Total année N-1"
    },
    {
        "code": "GA.1",
        "traduction": "Dot. d’exploit. - Dotations aux amortissements Montant France année N"
    },
    {
        "code": "GA.2",
        "traduction": "Dot. d’exploit. - Dotations aux amortissements Montant Export année N"
    },
    {
        "code": "GA.3",
        "traduction": "Dot. d’exploit. - Dotations aux amortissements Total année N"
    },
    {
        "code": "GA.4",
        "traduction": "Dot. d’exploit. - Dotations aux amortissements Total année N-1"
    },
    {
        "code": "GB.1",
        "traduction": "Dot. d’exploit. - Dotations aux provisions Montant France année N"
    },
    {
        "code": "GB.2",
        "traduction": "Dot. d’exploit. - Dotations aux provisions Montant Export année N"
    },
    {
        "code": "GB.3",
        "traduction": "Dot. d’exploit. - Dotations aux provisions Total année N"
    },
    {
        "code": "GB.4",
        "traduction": "Dot. d’exploit. - Dotations aux provisions Total année N-1"
    },
    {
        "code": "GC.1",
        "traduction": "Dot. d’exploit. Sur actif circulant : dotations aux provisions Montant France année N"
    },
    {
        "code": "GC.2",
        "traduction": "Dot. d’exploit. Sur actif circulant : dotations aux provisions Montant Export année N"
    },
    {
        "code": "GC.3",
        "traduction": "Dot. d’exploit. Sur actif circulant : dotations aux provisions Total année N"
    },
    {
        "code": "GC.4",
        "traduction": "Dot. d’exploit. Sur actif circulant : dotations aux provisions Total année N-1"
    },
    {
        "code": "GD.1",
        "traduction": "Dot. d’exploit. Pour risques et charges : dotations aux provisions Montant France année N"
    },
    {
        "code": "GD.2",
        "traduction": "Dot. d’exploit. Pour risques et charges : dotations aux provisions Montant Export année N"
    },
    {
        "code": "GD.3",
        "traduction": "Dot. d’exploit. Pour risques et charges : dotations aux provisions Total année N"
    },
    {
        "code": "GD.4",
        "traduction": "Dot. d’exploit. Pour risques et charges : dotations aux provisions Total année N-1"
    },
    {
        "code": "GE.1",
        "traduction": "Autres charges Montant France année N"
    },
    {
        "code": "GE.2",
        "traduction": "Autres charges Montant Export année N"
    },
    {
        "code": "GE.3",
        "traduction": "Autres charges Total année N"
    },
    {
        "code": "GE.4",
        "traduction": "Autres charges Total année N-1"
    },
    {
        "code": "GF.1",
        "traduction": "Total des charges d’exploitation (II) Montant France année N"
    },
    {
        "code": "GF.2",
        "traduction": "Total des charges d’exploitation (II) Montant Export année N"
    },
    {
        "code": "GF.3",
        "traduction": "Total des charges d’exploitation (II) Total année N"
    },
    {
        "code": "GF.4",
        "traduction": "Total des charges d’exploitation (II) Total année N-1"
    },
    {
        "code": "GG.1",
        "traduction": "- RESULTAT D’EXPLOITATION (I - II) Montant France année N"
    },
    {
        "code": "GG.2",
        "traduction": "- RESULTAT D’EXPLOITATION (I - II) Montant Export année N"
    },
    {
        "code": "GG.3",
        "traduction": "- RESULTAT D’EXPLOITATION (I - II) Total année N"
    },
    {
        "code": "GG.4",
        "traduction": "- RESULTAT D’EXPLOITATION (I - II) Total année N-1"
    },
    {
        "code": "GH.1",
        "traduction": "Bénéfice attribué ou perte transférée (III) Montant France année N"
    },
    {
        "code": "GH.2",
        "traduction": "Bénéfice attribué ou perte transférée (III) Montant Export année N"
    },
    {
        "code": "GH.3",
        "traduction": "Bénéfice attribué ou perte transférée (III) Total année N"
    },
    {
        "code": "GH.4",
        "traduction": "Bénéfice attribué ou perte transférée (III) Total année N-1"
    },
    {
        "code": "GI.1",
        "traduction": "Perte supportée ou bénéfice transféré (IV) Montant France année N"
    },
    {
        "code": "GI.2",
        "traduction": "Perte supportée ou bénéfice transféré (IV) Montant Export année N"
    },
    {
        "code": "GI.3",
        "traduction": "Perte supportée ou bénéfice transféré (IV) Total année N"
    },
    {
        "code": "GI.4",
        "traduction": "Perte supportée ou bénéfice transféré (IV) Total année N-1"
    },
    {
        "code": "GJ.1",
        "traduction": "Produits financiers de participations Montant France année N"
    },
    {
        "code": "GJ.2",
        "traduction": "Produits financiers de participations Montant Export année N"
    },
    {
        "code": "GJ.3",
        "traduction": "Produits financiers de participations Total année N"
    },
    {
        "code": "GJ.4",
        "traduction": "Produits financiers de participations Total année N-1"
    },
    {
        "code": "GK.1",
        "traduction": "Produits des autres valeurs mobilières et créances de l’actif immobilisé Montant France année N"
    },
    {
        "code": "GK.2",
        "traduction": "Produits des autres valeurs mobilières et créances de l’actif immobilisé Montant Export année N"
    },
    {
        "code": "GK.3",
        "traduction": "Produits des autres valeurs mobilières et créances de l’actif immobilisé Total année N"
    },
    {
        "code": "GK.4",
        "traduction": "Produits des autres valeurs mobilières et créances de l’actif immobilisé Total année N-1"
    },
    {
        "code": "GL.1",
        "traduction": "Autres intérêts et produits assimilés Montant France année N"
    },
    {
        "code": "GL.2",
        "traduction": "Autres intérêts et produits assimilés Montant Export année N"
    },
    {
        "code": "GL.3",
        "traduction": "Autres intérêts et produits assimilés Total année N"
    },
    {
        "code": "GL.4",
        "traduction": "Autres intérêts et produits assimilés Total année N-1"
    },
    {
        "code": "GM.1",
        "traduction": "Reprises sur provisions et transferts de charges Montant France année N"
    },
    {
        "code": "GM.2",
        "traduction": "Reprises sur provisions et transferts de charges Montant Export année N"
    },
    {
        "code": "GM.3",
        "traduction": "Reprises sur provisions et transferts de charges Total année N"
    },
    {
        "code": "GM.4",
        "traduction": "Reprises sur provisions et transferts de charges Total année N-1"
    },
    {
        "code": "GN.1",
        "traduction": "Différences positives de change Montant France année N"
    },
    {
        "code": "GN.2",
        "traduction": "Différences positives de change Montant Export année N"
    },
    {
        "code": "GN.3",
        "traduction": "Différences positives de change Total année N"
    },
    {
        "code": "GN.4",
        "traduction": "Différences positives de change Total année N-1"
    },
    {
        "code": "GO.1",
        "traduction": "Produits nets sur cessions de valeurs mobilières de placement Montant France année N"
    },
    {
        "code": "GO.2",
        "traduction": "Produits nets sur cessions de valeurs mobilières de placement Montant Export année N"
    },
    {
        "code": "GO.3",
        "traduction": "Produits nets sur cessions de valeurs mobilières de placement Total année N"
    },
    {
        "code": "GO.4",
        "traduction": "Produits nets sur cessions de valeurs mobilières de placement Total année N-1"
    },
    {
        "code": "GP.1",
        "traduction": "Total des produits financiers (V) Montant France année N"
    },
    {
        "code": "GP.2",
        "traduction": "Total des produits financiers (V) Montant Export année N"
    },
    {
        "code": "GP.3",
        "traduction": "Total des produits financiers (V) Total année N"
    },
    {
        "code": "GP.4",
        "traduction": "Total des produits financiers (V) Total année N-1"
    },
    {
        "code": "GQ.1",
        "traduction": "Dotations financières sur amortissements et provisions Montant France année N"
    },
    {
        "code": "GQ.2",
        "traduction": "Dotations financières sur amortissements et provisions Montant Export année N"
    },
    {
        "code": "GQ.3",
        "traduction": "Dotations financières sur amortissements et provisions Total année N"
    },
    {
        "code": "GQ.4",
        "traduction": "Dotations financières sur amortissements et provisions Total année N-1"
    },
    {
        "code": "GR.1",
        "traduction": "Intérêts et charges assimilées Montant France année N"
    },
    {
        "code": "GR.2",
        "traduction": "Intérêts et charges assimilées Montant Export année N"
    },
    {
        "code": "GR.3",
        "traduction": "Intérêts et charges assimilées Total année N"
    },
    {
        "code": "GR.4",
        "traduction": "Intérêts et charges assimilées Total année N-1"
    },
    {
        "code": "GS.1",
        "traduction": "Différences négatives de change Montant France année N"
    },
    {
        "code": "GS.2",
        "traduction": "Différences négatives de change Montant Export année N"
    },
    {
        "code": "GS.3",
        "traduction": "Différences négatives de change Total année N"
    },
    {
        "code": "GS.4",
        "traduction": "Différences négatives de change Total année N-1"
    },
    {
        "code": "GT.1",
        "traduction": "Charges nettes sur cessions de valeurs mobilières de placement Montant France année N"
    },
    {
        "code": "GT.2",
        "traduction": "Charges nettes sur cessions de valeurs mobilières de placement Montant Export année N"
    },
    {
        "code": "GT.3",
        "traduction": "Charges nettes sur cessions de valeurs mobilières de placement Total année N"
    },
    {
        "code": "GT.4",
        "traduction": "Charges nettes sur cessions de valeurs mobilières de placement Total année N-1"
    },
    {
        "code": "GU.1",
        "traduction": "Total des charges financières (VI) Montant France année N"
    },
    {
        "code": "GU.2",
        "traduction": "Total des charges financières (VI) Montant Export année N"
    },
    {
        "code": "GU.3",
        "traduction": "Total des charges financières (VI) Total année N"
    },
    {
        "code": "GU.4",
        "traduction": "Total des charges financières (VI) Total année N-1"
    },
    {
        "code": "GV.1",
        "traduction": "- RESULTAT FINANCIER (V - VI) Montant France année N"
    },
    {
        "code": "GV.2",
        "traduction": "- RESULTAT FINANCIER (V - VI) Montant Export année N"
    },
    {
        "code": "GV.3",
        "traduction": "- RESULTAT FINANCIER (V - VI) Total année N"
    },
    {
        "code": "GV.4",
        "traduction": "- RESULTAT FINANCIER (V - VI) Total année N-1"
    },
    {
        "code": "GW.1",
        "traduction": "- RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Montant France année N"
    },
    {
        "code": "GW.2",
        "traduction": "- RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Montant Export année N"
    },
    {
        "code": "GW.3",
        "traduction": "- RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Total année N"
    },
    {
        "code": "GW.4",
        "traduction": "- RESULTAT COURANT AVANT IMPOTS (I - II + III - IV + V - VI) Total année N-1"
    },
    {
        "code": "CDR2.1",
        "traduction": "Montant année N"
    },
    {
        "code": "CDR2.2",
        "traduction": "Montant année N-1"
    },
    {
        "code": "CDR2.3",
        "traduction": ""
    },
    {
        "code": "CDR2.4",
        "traduction": ""
    },
    {
        "code": "HA.1",
        "traduction": "Produits exceptionnels sur opérations de gestion Montant année N"
    },
    {
        "code": "HA.2",
        "traduction": "Produits exceptionnels sur opérations de gestion Montant année N-1"
    },
    {
        "code": "HA.3",
        "traduction": ""
    },
    {
        "code": "HA.4",
        "traduction": ""
    },
    {
        "code": "HB.1",
        "traduction": "Produits exceptionnels sur opérations en capital Montant année N"
    },
    {
        "code": "HB.2",
        "traduction": "Produits exceptionnels sur opérations en capital Montant année N-1"
    },
    {
        "code": "HB.3",
        "traduction": ""
    },
    {
        "code": "HB.4",
        "traduction": ""
    },
    {
        "code": "HC.1",
        "traduction": "Reprises sur provisions et transferts de charges Montant année N"
    },
    {
        "code": "HC.2",
        "traduction": "Reprises sur provisions et transferts de charges Montant année N-1"
    },
    {
        "code": "HC.3",
        "traduction": ""
    },
    {
        "code": "HC.4",
        "traduction": ""
    },
    {
        "code": "HD.1",
        "traduction": "Total des produits exceptionnels (VII) Montant année N"
    },
    {
        "code": "HD.2",
        "traduction": "Total des produits exceptionnels (VII) Montant année N-1"
    },
    {
        "code": "HD.3",
        "traduction": ""
    },
    {
        "code": "HD.4",
        "traduction": ""
    },
    {
        "code": "HE.1",
        "traduction": "Charges exceptionnelles sur opérations de gestion Montant année N"
    },
    {
        "code": "HE.2",
        "traduction": "Charges exceptionnelles sur opérations de gestion Montant année N-1"
    },
    {
        "code": "HE.3",
        "traduction": ""
    },
    {
        "code": "HE.4",
        "traduction": ""
    },
    {
        "code": "HF.1",
        "traduction": "Charges exceptionnelles sur opérations en capital Montant année N"
    },
    {
        "code": "HF.2",
        "traduction": "Charges exceptionnelles sur opérations en capital Montant année N-1"
    },
    {
        "code": "HF.3",
        "traduction": ""
    },
    {
        "code": "HF.4",
        "traduction": ""
    },
    {
        "code": "HG.1",
        "traduction": "Dotations exceptionnelles aux amortissements et provisions Montant année N"
    },
    {
        "code": "HG.2",
        "traduction": "Dotations exceptionnelles aux amortissements et provisions Montant année N-1"
    },
    {
        "code": "HG.3",
        "traduction": ""
    },
    {
        "code": "HG.4",
        "traduction": ""
    },
    {
        "code": "HH.1",
        "traduction": "Total des charges exceptionnelles (VIII) Montant année N"
    },
    {
        "code": "HH.2",
        "traduction": "Total des charges exceptionnelles (VIII) Montant année N-1"
    },
    {
        "code": "HH.3",
        "traduction": ""
    },
    {
        "code": "HH.4",
        "traduction": ""
    },
    {
        "code": "HI.1",
        "traduction": "- RESULTAT EXCEPTIONNEL (VII - VIII) Montant année N"
    },
    {
        "code": "HI.2",
        "traduction": "- RESULTAT EXCEPTIONNEL (VII - VIII) Montant année N-1"
    },
    {
        "code": "HI.3",
        "traduction": ""
    },
    {
        "code": "HI.4",
        "traduction": ""
    },
    {
        "code": "HJ.1",
        "traduction": "Participation des salariés aux résultats de l’entreprise Montant année N"
    },
    {
        "code": "HJ.2",
        "traduction": "Participation des salariés aux résultats de l’entreprise Montant année N-1"
    },
    {
        "code": "HJ.3",
        "traduction": ""
    },
    {
        "code": "HJ.4",
        "traduction": ""
    },
    {
        "code": "HK.1",
        "traduction": "Impôts sur les bénéfices Montant année N"
    },
    {
        "code": "HK.2",
        "traduction": "Impôts sur les bénéfices Montant année N-1"
    },
    {
        "code": "HK.3",
        "traduction": ""
    },
    {
        "code": "HK.4",
        "traduction": ""
    },
    {
        "code": "HL.1",
        "traduction": "TOTAL DES PRODUITS (I + III + V +VII) Montant année N"
    },
    {
        "code": "HL.2",
        "traduction": "TOTAL DES PRODUITS (I + III + V +VII) Montant année N-1"
    },
    {
        "code": "HL.3",
        "traduction": ""
    },
    {
        "code": "HL.4",
        "traduction": ""
    },
    {
        "code": "HM.1",
        "traduction": "TOTAL DES CHARGES (II + IV + VI + VIII + IX + X) Montant année N"
    },
    {
        "code": "HM.2",
        "traduction": "TOTAL DES CHARGES (II + IV + VI + VIII + IX + X) Montant année N-1"
    },
    {
        "code": "HM.3",
        "traduction": ""
    },
    {
        "code": "HM.4",
        "traduction": ""
    },
    {
        "code": "HN.1",
        "traduction": "BENEFICE OU PERTE (Total des produits - Total des charges) Montant année N"
    },
    {
        "code": "HN.2",
        "traduction": "BENEFICE OU PERTE (Total des produits - Total des charges) Montant année N-1"
    },
    {
        "code": "HN.3",
        "traduction": ""
    },
    {
        "code": "HN.4",
        "traduction": ""
    },
    {
        "code": "HP.1",
        "traduction": "Renvois : Crédit-bail mobilier Montant année N"
    },
    {
        "code": "HP.2",
        "traduction": "Renvois : Crédit-bail mobilier Montant année N-1"
    },
    {
        "code": "HP.3",
        "traduction": ""
    },
    {
        "code": "HP.4",
        "traduction": ""
    },
    {
        "code": "HQ.1",
        "traduction": "Renvois : Crédit-bail immobilier Montant année N"
    },
    {
        "code": "HQ.2",
        "traduction": "Renvois : Crédit-bail immobilier Montant année N-1"
    },
    {
        "code": "HQ.3",
        "traduction": ""
    },
    {
        "code": "HQ.4",
        "traduction": ""
    },
    {
        "code": "A1.1",
        "traduction": "Renvois : Transfert de charges Montant année N"
    },
    {
        "code": "A1.2",
        "traduction": "Renvois : Transfert de charges Montant année N-1"
    },
    {
        "code": "A1.3",
        "traduction": ""
    },
    {
        "code": "A1.4",
        "traduction": ""
    },
    {
        "code": "A2.1",
        "traduction": "Renvois : Cotisations personnelles de l’exploitant Montant année N"
    },
    {
        "code": "A2.2",
        "traduction": "Renvois : Cotisations personnelles de l’exploitant Montant année N-1"
    },
    {
        "code": "A2.3",
        "traduction": ""
    },
    {
        "code": "A2.4",
        "traduction": ""
    },
    {
        "code": "A3.1",
        "traduction": "Renvois : Redevances pour concessions de brevets, de licences (produits) Montant année N"
    },
    {
        "code": "A3.2",
        "traduction": "Renvois : Redevances pour concessions de brevets, de licences (produits) Montant année N-1"
    },
    {
        "code": "A3.3",
        "traduction": ""
    },
    {
        "code": "A3.4",
        "traduction": ""
    },
    {
        "code": "A4.1",
        "traduction": "Renvois : Redevances pour concessions de brevets, de licences (charges) Montant année N"
    },
    {
        "code": "A4.2",
        "traduction": "Renvois : Redevances pour concessions de brevets, de licences (charges) Montant année N-1"
    },
    {
        "code": "A4.3",
        "traduction": ""
    },
    {
        "code": "A4.4",
        "traduction": ""
    },
    {
        "code": "IMM.1",
        "traduction": "Poste à poste"
    },
    {
        "code": "IMM.2",
        "traduction": "Cessions"
    },
    {
        "code": "IMM.3",
        "traduction": "Valeur brute fin d’exercice"
    },
    {
        "code": "IMM.4",
        "traduction": ""
    },
    {
        "code": "CZ.1",
        "traduction": "ACQUISITIONS Frais d’établissement, et de développement ou de recherche Valeur brute début d’exercice"
    },
    {
        "code": "CZ.2",
        "traduction": "ACQUISITIONS Frais d’établissement, et de développement ou de recherche Réévaluation"
    },
    {
        "code": "CZ.3",
        "traduction": "ACQUISITIONS Frais d’établissement, et de développement ou de recherche Acquisition"
    },
    {
        "code": "CZ.4",
        "traduction": ""
    },
    {
        "code": "KD.1",
        "traduction": "ACQUISITIONS Total dont autres postes d’immobilisations incorporelles Valeur brute début d’exercice"
    },
    {
        "code": "KD.2",
        "traduction": "ACQUISITIONS Total dont autres postes d’immobilisations incorporelles Réévaluation"
    },
    {
        "code": "KD.3",
        "traduction": "ACQUISITIONS Total dont autres postes d’immobilisations incorporelles Acquisition"
    },
    {
        "code": "KD.4",
        "traduction": ""
    },
    {
        "code": "LN.1",
        "traduction": "ACQUISITIONS Total Immobilisations corporelles Valeur brute début d’exercice"
    },
    {
        "code": "LN.2",
        "traduction": "ACQUISITIONS Total Immobilisations corporelles Réévaluation"
    },
    {
        "code": "LN.3",
        "traduction": "ACQUISITIONS Total Immobilisations corporelles Acquisition"
    },
    {
        "code": "LN.4",
        "traduction": ""
    },
    {
        "code": "LQ.1",
        "traduction": "ACQUISITIONS Total Immobilisations financières Valeur brute début d’exercice"
    },
    {
        "code": "LQ.2",
        "traduction": "ACQUISITIONS Total Immobilisations financières Réévaluation"
    },
    {
        "code": "LQ.3",
        "traduction": "ACQUISITIONS Total Immobilisations financières Acquisition"
    },
    {
        "code": "LQ.4",
        "traduction": ""
    },
    {
        "code": "0G.1",
        "traduction": "ACQUISITIONS Total Général Valeur brute début d’exercice"
    },
    {
        "code": "0G.2",
        "traduction": "ACQUISITIONS Total Général Réévaluation"
    },
    {
        "code": "0G.3",
        "traduction": "ACQUISITIONS Total Général Acquisition"
    },
    {
        "code": "0G.4",
        "traduction": ""
    },
    {
        "code": "IN.1",
        "traduction": "DIMINUTIONS Frais d’établissement, et de développement ou de recherche Poste à poste"
    },
    {
        "code": "IN.2",
        "traduction": "DIMINUTIONS Frais d’établissement, et de développement ou de recherche Cessions"
    },
    {
        "code": "IN.3",
        "traduction": "DIMINUTIONS Frais d’établissement, et de développement ou de recherche Valeur brute fin d’exercice"
    },
    {
        "code": "IN.4",
        "traduction": ""
    },
    {
        "code": "IO.1",
        "traduction": "DIMINUTIONS Total dont autres postes d’immobilisations incorporelles Poste à poste"
    },
    {
        "code": "IO.2",
        "traduction": "DIMINUTIONS Total dont autres postes d’immobilisations incorporelles Cessions"
    },
    {
        "code": "IO.3",
        "traduction": "DIMINUTIONS Total dont autres postes d’immobilisations incorporelles Valeur brute fin d’exercice"
    },
    {
        "code": "IO.4",
        "traduction": ""
    },
    {
        "code": "MY.1",
        "traduction": "DIMINUTIONS Virement postes immobilisations corporelles en cours Poste à poste"
    },
    {
        "code": "MY.2",
        "traduction": "DIMINUTIONS Virement postes immobilisations corporelles en cours Cessions"
    },
    {
        "code": "MY.3",
        "traduction": "DIMINUTIONS Virement postes immobilisations corporelles en cours Valeur brute fin d’exercice"
    },
    {
        "code": "MY.4",
        "traduction": ""
    },
    {
        "code": "NC.1",
        "traduction": "DIMINUTIONS Virement postes - Avances et acomptes Poste à poste"
    },
    {
        "code": "NC.2",
        "traduction": "DIMINUTIONS Virement postes - Avances et acomptes Cessions"
    },
    {
        "code": "NC.3",
        "traduction": "DIMINUTIONS Virement postes - Avances et acomptes Valeur brute fin d’exercice"
    },
    {
        "code": "NC.4",
        "traduction": ""
    },
    {
        "code": "IY.1",
        "traduction": "DIMINUTIONS Total Immobilisations corporelles Poste à poste"
    },
    {
        "code": "IY.2",
        "traduction": "DIMINUTIONS Total Immobilisations corporelles Cessions"
    },
    {
        "code": "IY.3",
        "traduction": "DIMINUTIONS Total Immobilisations corporelles Valeur brute fin d’exercice"
    },
    {
        "code": "IY.4",
        "traduction": ""
    },
    {
        "code": "I2.1",
        "traduction": "DIMINUTIONS Prêts et immobilisations financières Poste à poste"
    },
    {
        "code": "I2.2",
        "traduction": "DIMINUTIONS Prêts et immobilisations financières Cessions"
    },
    {
        "code": "I2.3",
        "traduction": "DIMINUTIONS Prêts et immobilisations financières Valeur brute fin d’exercice"
    },
    {
        "code": "I2.4",
        "traduction": ""
    },
    {
        "code": "I3.1",
        "traduction": "DIMINUTIONS Total Immobilisations financières Poste à poste"
    },
    {
        "code": "I3.2",
        "traduction": "DIMINUTIONS Total Immobilisations financières Cessions"
    },
    {
        "code": "I3.3",
        "traduction": "DIMINUTIONS Total Immobilisations financières Valeur brute fin d’exercice"
    },
    {
        "code": "I3.4",
        "traduction": ""
    },
    {
        "code": "I4.1",
        "traduction": "DIMINUTIONS Total Général Poste à poste"
    },
    {
        "code": "I4.2",
        "traduction": "DIMINUTIONS Total Général Cessions"
    },
    {
        "code": "I4.3",
        "traduction": "DIMINUTIONS Total Général Valeur brute fin d’exercice"
    },
    {
        "code": "I4.4",
        "traduction": ""
    },
    {
        "code": "AMO.1",
        "traduction": "Montant début d’exercice"
    },
    {
        "code": "AMO.2",
        "traduction": "Augmentation : dotation exercice"
    },
    {
        "code": "AMO.3",
        "traduction": "Diminution reprise"
    },
    {
        "code": "AMO.4",
        "traduction": "Montant fin d’exercice"
    },
    {
        "code": "CY.1",
        "traduction": "AMORTISSEMENTS Frais d’établissement, et de développement ou de recherche Montant début d’exercice"
    },
    {
        "code": "CY.2",
        "traduction": "AMORTISSEMENTS Frais d’établissement, et de développement ou de recherche Augmentation : dotation exercice"
    },
    {
        "code": "CY.3",
        "traduction": "AMORTISSEMENTS Frais d’établissement, et de développement ou de recherche Diminution reprise"
    },
    {
        "code": "CY.4",
        "traduction": "AMORTISSEMENTS Frais d’établissement, et de développement ou de recherche Montant fin d’exercice"
    },
    {
        "code": "PE.1",
        "traduction": "AMORTISSEMENTS Total dont autres immobilisations incorporelles Montant début d’exercice"
    },
    {
        "code": "PE.2",
        "traduction": "AMORTISSEMENTS Total dont autres immobilisations incorporelles Augmentation : dotation exercice"
    },
    {
        "code": "PE.3",
        "traduction": "AMORTISSEMENTS Total dont autres immobilisations incorporelles Diminution reprise"
    },
    {
        "code": "PE.4",
        "traduction": "AMORTISSEMENTS Total dont autres immobilisations incorporelles Montant fin d’exercice"
    },
    {
        "code": "QU.1",
        "traduction": "AMORTISSEMENTS Total Immobilisations corporelles Montant début d’exercice"
    },
    {
        "code": "QU.2",
        "traduction": "AMORTISSEMENTS Total Immobilisations corporelles Augmentation : dotation exercice"
    },
    {
        "code": "QU.3",
        "traduction": "AMORTISSEMENTS Total Immobilisations corporelles Diminution reprise"
    },
    {
        "code": "QU.4",
        "traduction": "AMORTISSEMENTS Total Immobilisations corporelles Montant fin d’exercice"
    },
    {
        "code": "0N.1",
        "traduction": "AMORTISSEMENTS Total Général Montant début d’exercice"
    },
    {
        "code": "0N.2",
        "traduction": "AMORTISSEMENTS Total Général Augmentation : dotation exercice"
    },
    {
        "code": "0N.3",
        "traduction": "AMORTISSEMENTS Total Général Diminution reprise"
    },
    {
        "code": "0N.4",
        "traduction": "AMORTISSEMENTS Total Général Montant fin d’exercice"
    },
    {
        "code": "Z9.1",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Montant début d’exercice"
    },
    {
        "code": "Z9.2",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Augmentation : dotation exercice"
    },
    {
        "code": "Z9.3",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Diminution reprise"
    },
    {
        "code": "Z9.4",
        "traduction": "Charges à répartir ou frais d’émission d’emprunt Montant fin d’exercice"
    },
    {
        "code": "SP.1",
        "traduction": "mvt. sur charges rép. - Primes de remboursement des obligations Montant début d’exercice"
    },
    {
        "code": "SP.2",
        "traduction": "mvt. sur charges rép. - Primes de remboursement des obligations Augmentation : dotation exercice"
    },
    {
        "code": "SP.3",
        "traduction": "mvt. sur charges rép. - Primes de remboursement des obligations Diminution reprise"
    },
    {
        "code": "SP.4",
        "traduction": "mvt. sur charges rép. - Primes de remboursement des obligations Montant fin d’exercice"
    },
    {
        "code": "PRO.1",
        "traduction": "Montant début d’exercice"
    },
    {
        "code": "PRO.2",
        "traduction": "Augmentation : dotation exercice"
    },
    {
        "code": "PRO.3",
        "traduction": "Diminution reprise"
    },
    {
        "code": "PRO.4",
        "traduction": "Montant fin d’exercice"
    },
    {
        "code": "3X.1",
        "traduction": "Amortissements dérogatoires Montant début d’exercice"
    },
    {
        "code": "3X.2",
        "traduction": "Amortissements dérogatoires Augmentation : dotation exercice"
    },
    {
        "code": "3X.3",
        "traduction": "Amortissements dérogatoires Diminution reprise"
    },
    {
        "code": "3X.4",
        "traduction": "Amortissements dérogatoires Montant fin d’exercice"
    },
    {
        "code": "3Z.1",
        "traduction": "Total Provisions réglementées Montant début d’exercice"
    },
    {
        "code": "3Z.2",
        "traduction": "Total Provisions réglementées Augmentation : dotation exercice"
    },
    {
        "code": "3Z.3",
        "traduction": "Total Provisions réglementées Diminution reprise"
    },
    {
        "code": "3Z.4",
        "traduction": "Total Provisions réglementées Montant fin d’exercice"
    },
    {
        "code": "4A.1",
        "traduction": "Provisions pour litiges Montant début d’exercice"
    },
    {
        "code": "4A.2",
        "traduction": "Provisions pour litiges Augmentation : dotation exercice"
    },
    {
        "code": "4A.3",
        "traduction": "Provisions pour litiges Diminution reprise"
    },
    {
        "code": "4A.4",
        "traduction": "Provisions pour litiges Montant fin d’exercice"
    },
    {
        "code": "4E.1",
        "traduction": "Provisions pour garanties données aux clients Montant début d’exercice"
    },
    {
        "code": "4E.2",
        "traduction": "Provisions pour garanties données aux clients Augmentation : dotation exercice"
    },
    {
        "code": "4E.3",
        "traduction": "Provisions pour garanties données aux clients Diminution reprise"
    },
    {
        "code": "4E.4",
        "traduction": "Provisions pour garanties données aux clients Montant fin d’exercice"
    },
    {
        "code": "4J.1",
        "traduction": "Provisions pour perte sur marchés à terme Montant début d’exercice"
    },
    {
        "code": "4J.2",
        "traduction": "Provisions pour perte sur marchés à terme Augmentation : dotation exercice"
    },
    {
        "code": "4J.3",
        "traduction": "Provisions pour perte sur marchés à terme Diminution reprise"
    },
    {
        "code": "4J.4",
        "traduction": "Provisions pour perte sur marchés à terme Montant fin d’exercice"
    },
    {
        "code": "4N.1",
        "traduction": "Provisions pour amendes et pénalités Montant début d’exercice"
    },
    {
        "code": "4N.2",
        "traduction": "Provisions pour amendes et pénalités Augmentation : dotation exercice"
    },
    {
        "code": "4N.3",
        "traduction": "Provisions pour amendes et pénalités Diminution reprise"
    },
    {
        "code": "4N.4",
        "traduction": "Provisions pour amendes et pénalités Montant fin d’exercice"
    },
    {
        "code": "4T.1",
        "traduction": "Provisions pour perte de change Montant début d’exercice"
    },
    {
        "code": "4T.2",
        "traduction": "Provisions pour perte de change Augmentation : dotation exercice"
    },
    {
        "code": "4T.3",
        "traduction": "Provisions pour perte de change Diminution reprise"
    },
    {
        "code": "4T.4",
        "traduction": "Provisions pour perte de change Montant fin d’exercice"
    },
    {
        "code": "4X.1",
        "traduction": "Provisions pour pensions et obligations similaires Montant début d’exercice"
    },
    {
        "code": "4X.2",
        "traduction": "Provisions pour pensions et obligations similaires Augmentation : dotation exercice"
    },
    {
        "code": "4X.3",
        "traduction": "Provisions pour pensions et obligations similaires Diminution reprise"
    },
    {
        "code": "4X.4",
        "traduction": "Provisions pour pensions et obligations similaires Montant fin d’exercice"
    },
    {
        "code": "5B.1",
        "traduction": "Provisions pour impôts Montant début d’exercice"
    },
    {
        "code": "5B.2",
        "traduction": "Provisions pour impôts Augmentation : dotation exercice"
    },
    {
        "code": "5B.3",
        "traduction": "Provisions pour impôts Diminution reprise"
    },
    {
        "code": "5B.4",
        "traduction": "Provisions pour impôts Montant fin d’exercice"
    },
    {
        "code": "5F.1",
        "traduction": "Provisions pour renouvellement des immobilisations Montant début d’exercice"
    },
    {
        "code": "5F.2",
        "traduction": "Provisions pour renouvellement des immobilisations Augmentation : dotation exercice"
    },
    {
        "code": "5F.3",
        "traduction": "Provisions pour renouvellement des immobilisations Diminution reprise"
    },
    {
        "code": "5F.4",
        "traduction": "Provisions pour renouvellement des immobilisations Montant fin d’exercice"
    },
    {
        "code": "EO.1",
        "traduction": "Provisions pour gros entretien et grandes révisions ou grosses réparations Montant début d’exercice"
    },
    {
        "code": "EO.2",
        "traduction": "Provisions pour gros entretien et grandes révisions ou grosses réparations Augmentation : dotation exercice"
    },
    {
        "code": "EO.3",
        "traduction": "Provisions pour gros entretien et grandes révisions ou grosses réparations Diminution reprise"
    },
    {
        "code": "EO.4",
        "traduction": "Provisions pour gros entretien et grandes révisions ou grosses réparations Montant fin d’exercice"
    },
    {
        "code": "5R.1",
        "traduction": "Provisions pour charges sociales et fiscales sur congés à payer Montant début d’exercice"
    },
    {
        "code": "5R.2",
        "traduction": "Provisions pour charges sociales et fiscales sur congés à payer Augmentation : dotation exercice"
    },
    {
        "code": "5R.3",
        "traduction": "Provisions pour charges sociales et fiscales sur congés à payer Diminution reprise"
    },
    {
        "code": "5R.4",
        "traduction": "Provisions pour charges sociales et fiscales sur congés à payer Montant fin d’exercice"
    },
    {
        "code": "5V.1",
        "traduction": "Autres provisions pour risques et charges Montant début d’exercice"
    },
    {
        "code": "5V.2",
        "traduction": "Autres provisions pour risques et charges Augmentation : dotation exercice"
    },
    {
        "code": "5V.3",
        "traduction": "Autres provisions pour risques et charges Diminution reprise"
    },
    {
        "code": "5V.4",
        "traduction": "Autres provisions pour risques et charges Montant fin d’exercice"
    },
    {
        "code": "5Z.1",
        "traduction": "Total Provisions pour risques et charges Montant début d’exercice"
    },
    {
        "code": "5Z.2",
        "traduction": "Total Provisions pour risques et charges Augmentation : dotation exercice"
    },
    {
        "code": "5Z.3",
        "traduction": "Total Provisions pour risques et charges Diminution reprise"
    },
    {
        "code": "5Z.4",
        "traduction": "Total Provisions pour risques et charges Montant fin d’exercice"
    },
    {
        "code": "6A.1",
        "traduction": "sur immobilisations – incorporelles Montant début d’exercice"
    },
    {
        "code": "6A.2",
        "traduction": "sur immobilisations – incorporelles Augmentation : dotation exercice"
    },
    {
        "code": "6A.3",
        "traduction": "sur immobilisations – incorporelles Diminution reprise"
    },
    {
        "code": "6A.4",
        "traduction": "sur immobilisations – incorporelles Montant fin d’exercice"
    },
    {
        "code": "6E.1",
        "traduction": "sur immobilisations – corporelles Montant début d’exercice"
    },
    {
        "code": "6E.2",
        "traduction": "sur immobilisations – corporelles Augmentation : dotation exercice"
    },
    {
        "code": "6E.3",
        "traduction": "sur immobilisations – corporelles Diminution reprise"
    },
    {
        "code": "6E.4",
        "traduction": "sur immobilisations – corporelles Montant fin d’exercice"
    },
    {
        "code": "02.1",
        "traduction": "sur immobilisations – titres mis en équivalence Montant début d’exercice"
    },
    {
        "code": "02.2",
        "traduction": "sur immobilisations – titres mis en équivalence Augmentation : dotation exercice"
    },
    {
        "code": "02.3",
        "traduction": "sur immobilisations – titres mis en équivalence Diminution reprise"
    },
    {
        "code": "02.4",
        "traduction": "sur immobilisations – titres mis en équivalence Montant fin d’exercice"
    },
    {
        "code": "9U.1",
        "traduction": "sur immobilisations – titres de participation Montant début d’exercice"
    },
    {
        "code": "9U.2",
        "traduction": "sur immobilisations – titres de participation Augmentation : dotation exercice"
    },
    {
        "code": "9U.3",
        "traduction": "sur immobilisations – titres de participation Diminution reprise"
    },
    {
        "code": "9U.4",
        "traduction": "sur immobilisations – titres de participation Montant fin d’exercice"
    },
    {
        "code": "06.1",
        "traduction": "sur immobilisations –autres immobilisations financières Montant début d’exercice"
    },
    {
        "code": "06.2",
        "traduction": "sur immobilisations –autres immobilisations financières Augmentation : dotation exercice"
    },
    {
        "code": "06.3",
        "traduction": "sur immobilisations –autres immobilisations financières Diminution reprise"
    },
    {
        "code": "06.4",
        "traduction": "sur immobilisations –autres immobilisations financières Montant fin d’exercice"
    },
    {
        "code": "6N.1",
        "traduction": "Sur stocks et en cours Montant début d’exercice"
    },
    {
        "code": "6N.2",
        "traduction": "Sur stocks et en cours Augmentation : dotation exercice"
    },
    {
        "code": "6N.3",
        "traduction": "Sur stocks et en cours Diminution reprise"
    },
    {
        "code": "6N.4",
        "traduction": "Sur stocks et en cours Montant fin d’exercice"
    },
    {
        "code": "6T.1",
        "traduction": "Sur comptes clients Montant début d’exercice"
    },
    {
        "code": "6T.2",
        "traduction": "Sur comptes clients Augmentation : dotation exercice"
    },
    {
        "code": "6T.3",
        "traduction": "Sur comptes clients Diminution reprise"
    },
    {
        "code": "6T.4",
        "traduction": "Sur comptes clients Montant fin d’exercice"
    },
    {
        "code": "6X.1",
        "traduction": "Autres provisions pour dépréciation Montant début d’exercice"
    },
    {
        "code": "6X.2",
        "traduction": "Autres provisions pour dépréciation Augmentation : dotation exercice"
    },
    {
        "code": "6X.3",
        "traduction": "Autres provisions pour dépréciation Diminution reprise"
    },
    {
        "code": "6X.4",
        "traduction": "Autres provisions pour dépréciation Montant fin d’exercice"
    },
    {
        "code": "7B.1",
        "traduction": "Total Provisions pour dépréciation Montant début d’exercice"
    },
    {
        "code": "7B.2",
        "traduction": "Total Provisions pour dépréciation Augmentation : dotation exercice"
    },
    {
        "code": "7B.3",
        "traduction": "Total Provisions pour dépréciation Diminution reprise"
    },
    {
        "code": "7B.4",
        "traduction": "Total Provisions pour dépréciation Montant fin d’exercice"
    },
    {
        "code": "7C.1",
        "traduction": "TOTAL GENERAL Montant début d’exercice"
    },
    {
        "code": "7C.2",
        "traduction": "TOTAL GENERAL Augmentation : dotation exercice"
    },
    {
        "code": "7C.3",
        "traduction": "TOTAL GENERAL Diminution reprise"
    },
    {
        "code": "7C.4",
        "traduction": "TOTAL GENERAL Montant fin d’exercice"
    },
    {
        "code": "UE.1",
        "traduction": "dont dotations et reprises : - d’Exploitation Montant début d’exercice"
    },
    {
        "code": "UE.2",
        "traduction": "dont dotations et reprises : - d’Exploitation Augmentation : dotation exercice"
    },
    {
        "code": "UE.3",
        "traduction": "dont dotations et reprises : - d’Exploitation Diminution reprise"
    },
    {
        "code": "UE.4",
        "traduction": "dont dotations et reprises : - d’Exploitation Montant fin d’exercice"
    },
    {
        "code": "UG.1",
        "traduction": "dont dotations et reprises : - Financières Montant début d’exercice"
    },
    {
        "code": "UG.2",
        "traduction": "dont dotations et reprises : - Financières Augmentation : dotation exercice"
    },
    {
        "code": "UG.3",
        "traduction": "dont dotations et reprises : - Financières Diminution reprise"
    },
    {
        "code": "UG.4",
        "traduction": "dont dotations et reprises : - Financières Montant fin d’exercice"
    },
    {
        "code": "UJ.1",
        "traduction": "dont dotations et reprises : - Exceptionnelles Montant début d’exercice"
    },
    {
        "code": "UJ.2",
        "traduction": "dont dotations et reprises : - Exceptionnelles Augmentation : dotation exercice"
    },
    {
        "code": "UJ.3",
        "traduction": "dont dotations et reprises : - Exceptionnelles Diminution reprise"
    },
    {
        "code": "UJ.4",
        "traduction": "dont dotations et reprises : - Exceptionnelles Montant fin d’exercice"
    },
    {
        "code": "CREDET.1",
        "traduction": "Montant brut"
    },
    {
        "code": "CREDET.2",
        "traduction": "Montant 1 an au plus"
    },
    {
        "code": "CREDET.3",
        "traduction": "Montant de 1 à 5 ans"
    },
    {
        "code": "CREDET.4",
        "traduction": "Montant plus de 5 ans"
    },
    {
        "code": "UL.1",
        "traduction": "Créances rattachées à des participations Montant brut"
    },
    {
        "code": "UL.2",
        "traduction": "Créances rattachées à des participations Montant 1 an au plus"
    },
    {
        "code": "UL.3",
        "traduction": "Créances rattachées à des participations Montant de 1 à 5 ans"
    },
    {
        "code": "UL.4",
        "traduction": "Créances rattachées à des participations Montant plus de 5 ans"
    },
    {
        "code": "UP.1",
        "traduction": "Prêts Montant brut"
    },
    {
        "code": "UP.2",
        "traduction": "Prêts Montant 1 an au plus"
    },
    {
        "code": "UP.3",
        "traduction": "Prêts Montant de 1 à 5 ans"
    },
    {
        "code": "UP.4",
        "traduction": "Prêts Montant plus de 5 ans"
    },
    {
        "code": "UT.1",
        "traduction": "Autres immobilisations financières Montant brut"
    },
    {
        "code": "UT.2",
        "traduction": "Autres immobilisations financières Montant 1 an au plus"
    },
    {
        "code": "UT.3",
        "traduction": "Autres immobilisations financières Montant de 1 à 5 ans"
    },
    {
        "code": "UT.4",
        "traduction": "Autres immobilisations financières Montant plus de 5 ans"
    },
    {
        "code": "VA.1",
        "traduction": "Clients douteux ou litigieux Montant brut"
    },
    {
        "code": "VA.2",
        "traduction": "Clients douteux ou litigieux Montant 1 an au plus"
    },
    {
        "code": "VA.3",
        "traduction": "Clients douteux ou litigieux Montant de 1 à 5 ans"
    },
    {
        "code": "VA.4",
        "traduction": "Clients douteux ou litigieux Montant plus de 5 ans"
    },
    {
        "code": "UX.1",
        "traduction": "Autres créances clients Montant brut"
    },
    {
        "code": "UX.2",
        "traduction": "Autres créances clients Montant 1 an au plus"
    },
    {
        "code": "UX.3",
        "traduction": "Autres créances clients Montant de 1 à 5 ans"
    },
    {
        "code": "UX.4",
        "traduction": "Autres créances clients Montant plus de 5 ans"
    },
    {
        "code": "UO.1",
        "traduction": "(provision pour dépréciation antérieurement constituée) Montant brut"
    },
    {
        "code": "UO.2",
        "traduction": "(provision pour dépréciation antérieurement constituée) Montant 1 an au plus"
    },
    {
        "code": "UO.3",
        "traduction": "(provision pour dépréciation antérieurement constituée) Montant de 1 à 5 ans"
    },
    {
        "code": "UO.4",
        "traduction": "(provision pour dépréciation antérieurement constituée) Montant plus de 5 ans"
    },
    {
        "code": "Z1.1",
        "traduction": "Créances représentatives de titres prêtés Montant brut"
    },
    {
        "code": "Z1.2",
        "traduction": "Créances représentatives de titres prêtés Montant 1 an au plus"
    },
    {
        "code": "Z1.3",
        "traduction": "Créances représentatives de titres prêtés Montant de 1 à 5 ans"
    },
    {
        "code": "Z1.4",
        "traduction": "Créances représentatives de titres prêtés Montant plus de 5 ans"
    },
    {
        "code": "UY.1",
        "traduction": "Personnel et comptes rattachés Montant brut"
    },
    {
        "code": "UY.2",
        "traduction": "Personnel et comptes rattachés Montant 1 an au plus"
    },
    {
        "code": "UY.3",
        "traduction": "Personnel et comptes rattachés Montant de 1 à 5 ans"
    },
    {
        "code": "UY.4",
        "traduction": "Personnel et comptes rattachés Montant plus de 5 ans"
    },
    {
        "code": "UZ.1",
        "traduction": "Sécurité Sociale, autres organismes sociaux Montant brut"
    },
    {
        "code": "UZ.2",
        "traduction": "Sécurité Sociale, autres organismes sociaux Montant 1 an au plus"
    },
    {
        "code": "UZ.3",
        "traduction": "Sécurité Sociale, autres organismes sociaux Montant de 1 à 5 ans"
    },
    {
        "code": "UZ.4",
        "traduction": "Sécurité Sociale, autres organismes sociaux Montant plus de 5 ans"
    },
    {
        "code": "VM.1",
        "traduction": "Impôts sur les bénéfices Montant brut"
    },
    {
        "code": "VM.2",
        "traduction": "Impôts sur les bénéfices Montant 1 an au plus"
    },
    {
        "code": "VM.3",
        "traduction": "Impôts sur les bénéfices Montant de 1 à 5 ans"
    },
    {
        "code": "VM.4",
        "traduction": "Impôts sur les bénéfices Montant plus de 5 ans"
    },
    {
        "code": "VB.1",
        "traduction": "T. V. A. Montant brut"
    },
    {
        "code": "VB.2",
        "traduction": "T. V. A. Montant 1 an au plus"
    },
    {
        "code": "VB.3",
        "traduction": "T. V. A. Montant de 1 à 5 ans"
    },
    {
        "code": "VB.4",
        "traduction": "T. V. A. Montant plus de 5 ans"
    },
    {
        "code": "VN.1",
        "traduction": "Autres impôts, taxes versements assimilés Montant brut"
    },
    {
        "code": "VN.2",
        "traduction": "Autres impôts, taxes versements assimilés Montant 1 an au plus"
    },
    {
        "code": "VN.3",
        "traduction": "Autres impôts, taxes versements assimilés Montant de 1 à 5 ans"
    },
    {
        "code": "VN.4",
        "traduction": "Autres impôts, taxes versements assimilés Montant plus de 5 ans"
    },
    {
        "code": "VP.1",
        "traduction": "Divers Montant brut"
    },
    {
        "code": "VP.2",
        "traduction": "Divers Montant 1 an au plus"
    },
    {
        "code": "VP.3",
        "traduction": "Divers Montant de 1 à 5 ans"
    },
    {
        "code": "VP.4",
        "traduction": "Divers Montant plus de 5 ans"
    },
    {
        "code": "VC.1",
        "traduction": "Groupe et associés Montant brut"
    },
    {
        "code": "VC.2",
        "traduction": "Groupe et associés Montant 1 an au plus"
    },
    {
        "code": "VC.3",
        "traduction": "Groupe et associés Montant de 1 à 5 ans"
    },
    {
        "code": "VC.4",
        "traduction": "Groupe et associés Montant plus de 5 ans"
    },
    {
        "code": "VR.1",
        "traduction": "Débiteurs divers (dont créances relatives à des opérations de pension de titres) Montant brut"
    },
    {
        "code": "VR.2",
        "traduction": "Débiteurs divers (dont créances relatives à des opérations de pension de titres) Montant 1 an au plus"
    },
    {
        "code": "VR.3",
        "traduction": "Débiteurs divers (dont créances relatives à des opérations de pension de titres) Montant de 1 à 5 ans"
    },
    {
        "code": "VR.4",
        "traduction": "Débiteurs divers (dont créances relatives à des opérations de pension de titres) Montant plus de 5 ans"
    },
    {
        "code": "VS.1",
        "traduction": "Charges constatées d’avance Montant brut"
    },
    {
        "code": "VS.2",
        "traduction": "Charges constatées d’avance Montant 1 an au plus"
    },
    {
        "code": "VS.3",
        "traduction": "Charges constatées d’avance Montant de 1 à 5 ans"
    },
    {
        "code": "VS.4",
        "traduction": "Charges constatées d’avance Montant plus de 5 ans"
    },
    {
        "code": "VT.1",
        "traduction": "TOTAL – ETAT DES CREANCES Montant brut"
    },
    {
        "code": "VT.2",
        "traduction": "TOTAL – ETAT DES CREANCES Montant 1 an au plus"
    },
    {
        "code": "VT.3",
        "traduction": "TOTAL – ETAT DES CREANCES Montant de 1 à 5 ans"
    },
    {
        "code": "VT.4",
        "traduction": "TOTAL – ETAT DES CREANCES Montant plus de 5 ans"
    },
    {
        "code": "7Y.1",
        "traduction": "Emprunts obligataires convertibles brut à un an au plus Montant brut"
    },
    {
        "code": "7Y.2",
        "traduction": "Emprunts obligataires convertibles brut à un an au plus Montant 1 an au plus"
    },
    {
        "code": "7Y.3",
        "traduction": "Emprunts obligataires convertibles brut à un an au plus Montant de 1 à 5 ans"
    },
    {
        "code": "7Y.4",
        "traduction": "Emprunts obligataires convertibles brut à un an au plus Montant plus de 5 ans"
    },
    {
        "code": "7Z.1",
        "traduction": "Autres emprunts obligataires brut à un an au plus Montant brut"
    },
    {
        "code": "7Z.2",
        "traduction": "Autres emprunts obligataires brut à un an au plus Montant 1 an au plus"
    },
    {
        "code": "7Z.3",
        "traduction": "Autres emprunts obligataires brut à un an au plus Montant de 1 à 5 ans"
    },
    {
        "code": "7Z.4",
        "traduction": "Autres emprunts obligataires brut à un an au plus Montant plus de 5 ans"
    },
    {
        "code": "VG.1",
        "traduction": "Emprunts à 1 an maximum à l’origine Montant brut"
    },
    {
        "code": "VG.2",
        "traduction": "Emprunts à 1 an maximum à l’origine Montant 1 an au plus"
    },
    {
        "code": "VG.3",
        "traduction": "Emprunts à 1 an maximum à l’origine Montant de 1 à 5 ans"
    },
    {
        "code": "VG.4",
        "traduction": "Emprunts à 1 an maximum à l’origine Montant plus de 5 ans"
    },
    {
        "code": "VH.1",
        "traduction": "Emprunts à plus d’1 an à l’origine Montant brut"
    },
    {
        "code": "VH.2",
        "traduction": "Emprunts à plus d’1 an à l’origine Montant 1 an au plus"
    },
    {
        "code": "VH.3",
        "traduction": "Emprunts à plus d’1 an à l’origine Montant de 1 à 5 ans"
    },
    {
        "code": "VH.4",
        "traduction": "Emprunts à plus d’1 an à l’origine Montant plus de 5 ans"
    },
    {
        "code": "8A.1",
        "traduction": "Emprunts et dettes financières divers Montant brut"
    },
    {
        "code": "8A.2",
        "traduction": "Emprunts et dettes financières divers Montant 1 an au plus"
    },
    {
        "code": "8A.3",
        "traduction": "Emprunts et dettes financières divers Montant de 1 à 5 ans"
    },
    {
        "code": "8A.4",
        "traduction": "Emprunts et dettes financières divers Montant plus de 5 ans"
    },
    {
        "code": "8B.1",
        "traduction": "Fournisseurs et comptes rattachés Montant brut"
    },
    {
        "code": "8B.2",
        "traduction": "Fournisseurs et comptes rattachés Montant 1 an au plus"
    },
    {
        "code": "8B.3",
        "traduction": "Fournisseurs et comptes rattachés Montant de 1 à 5 ans"
    },
    {
        "code": "8B.4",
        "traduction": "Fournisseurs et comptes rattachés Montant plus de 5 ans"
    },
    {
        "code": "8C.1",
        "traduction": "Personnel et comptes rattachés Montant brut"
    },
    {
        "code": "8C.2",
        "traduction": "Personnel et comptes rattachés Montant 1 an au plus"
    },
    {
        "code": "8C.3",
        "traduction": "Personnel et comptes rattachés Montant de 1 à 5 ans"
    },
    {
        "code": "8C.4",
        "traduction": "Personnel et comptes rattachés Montant plus de 5 ans"
    },
    {
        "code": "8D.1",
        "traduction": "Sécurité sociale et autres organismes sociaux Montant brut"
    },
    {
        "code": "8D.2",
        "traduction": "Sécurité sociale et autres organismes sociaux Montant 1 an au plus"
    },
    {
        "code": "8D.3",
        "traduction": "Sécurité sociale et autres organismes sociaux Montant de 1 à 5 ans"
    },
    {
        "code": "8D.4",
        "traduction": "Sécurité sociale et autres organismes sociaux Montant plus de 5 ans"
    },
    {
        "code": "8E.1",
        "traduction": "Impôts sur les bénéfices Montant brut"
    },
    {
        "code": "8E.2",
        "traduction": "Impôts sur les bénéfices Montant 1 an au plus"
    },
    {
        "code": "8E.3",
        "traduction": "Impôts sur les bénéfices Montant de 1 à 5 ans"
    },
    {
        "code": "8E.4",
        "traduction": "Impôts sur les bénéfices Montant plus de 5 ans"
    },
    {
        "code": "VW.1",
        "traduction": "T.V.A. Montant brut"
    },
    {
        "code": "VW.2",
        "traduction": "T.V.A. Montant 1 an au plus"
    },
    {
        "code": "VW.3",
        "traduction": "T.V.A. Montant de 1 à 5 ans"
    },
    {
        "code": "VW.4",
        "traduction": "T.V.A. Montant plus de 5 ans"
    },
    {
        "code": "VX.1",
        "traduction": "Obligations cautionnées Montant brut"
    },
    {
        "code": "VX.2",
        "traduction": "Obligations cautionnées Montant 1 an au plus"
    },
    {
        "code": "VX.3",
        "traduction": "Obligations cautionnées Montant de 1 à 5 ans"
    },
    {
        "code": "VX.4",
        "traduction": "Obligations cautionnées Montant plus de 5 ans"
    },
    {
        "code": "VQ.1",
        "traduction": "Autres impôts, taxes et assimilés Montant brut"
    },
    {
        "code": "VQ.2",
        "traduction": "Autres impôts, taxes et assimilés Montant 1 an au plus"
    },
    {
        "code": "VQ.3",
        "traduction": "Autres impôts, taxes et assimilés Montant de 1 à 5 ans"
    },
    {
        "code": "VQ.4",
        "traduction": "Autres impôts, taxes et assimilés Montant plus de 5 ans"
    },
    {
        "code": "8J.1",
        "traduction": "Dettes sur immobilisations et comptes rattachés Montant brut"
    },
    {
        "code": "8J.2",
        "traduction": "Dettes sur immobilisations et comptes rattachés Montant 1 an au plus"
    },
    {
        "code": "8J.3",
        "traduction": "Dettes sur immobilisations et comptes rattachés Montant de 1 à 5 ans"
    },
    {
        "code": "8J.4",
        "traduction": "Dettes sur immobilisations et comptes rattachés Montant plus de 5 ans"
    },
    {
        "code": "VI.1",
        "traduction": "Groupe et associés Montant brut"
    },
    {
        "code": "VI.2",
        "traduction": "Groupe et associés Montant 1 an au plus"
    },
    {
        "code": "VI.3",
        "traduction": "Groupe et associés Montant de 1 à 5 ans"
    },
    {
        "code": "VI.4",
        "traduction": "Groupe et associés Montant plus de 5 ans"
    },
    {
        "code": "8K.1",
        "traduction": "Autres dettes (dont dettes relatives à des opérations de pension de titre) Montant brut"
    },
    {
        "code": "8K.2",
        "traduction": "Autres dettes (dont dettes relatives à des opérations de pension de titre) Montant 1 an au plus"
    },
    {
        "code": "8K.3",
        "traduction": "Autres dettes (dont dettes relatives à des opérations de pension de titre) Montant de 1 à 5 ans"
    },
    {
        "code": "8K.4",
        "traduction": "Autres dettes (dont dettes relatives à des opérations de pension de titre) Montant plus de 5 ans"
    },
    {
        "code": "Z2.1",
        "traduction": "Dette représentative de titres empruntés Montant brut"
    },
    {
        "code": "Z2.2",
        "traduction": "Dette représentative de titres empruntés Montant 1 an au plus"
    },
    {
        "code": "Z2.3",
        "traduction": "Dette représentative de titres empruntés Montant de 1 à 5 ans"
    },
    {
        "code": "Z2.4",
        "traduction": "Dette représentative de titres empruntés Montant plus de 5 ans"
    },
    {
        "code": "8L.1",
        "traduction": "Produits constatés d’avance Montant brut"
    },
    {
        "code": "8L.2",
        "traduction": "Produits constatés d’avance Montant 1 an au plus"
    },
    {
        "code": "8L.3",
        "traduction": "Produits constatés d’avance Montant de 1 à 5 ans"
    },
    {
        "code": "8L.4",
        "traduction": "Produits constatés d’avance Montant plus de 5 ans"
    },
    {
        "code": "VY.1",
        "traduction": "TOTAL – ETAT DES DETTES Montant brut"
    },
    {
        "code": "VY.2",
        "traduction": "TOTAL – ETAT DES DETTES Montant 1 an au plus"
    },
    {
        "code": "VY.3",
        "traduction": "TOTAL – ETAT DES DETTES Montant de 1 à 5 ans"
    },
    {
        "code": "VY.4",
        "traduction": "TOTAL – ETAT DES DETTES Montant plus de 5 ans"
    },
    {
        "code": "VJ.1",
        "traduction": "Emprunts souscrits en cours d’exercice Montant brut"
    },
    {
        "code": "VJ.2",
        "traduction": "Emprunts souscrits en cours d’exercice Montant 1 an au plus"
    },
    {
        "code": "VJ.3",
        "traduction": "Emprunts souscrits en cours d’exercice Montant de 1 à 5 ans"
    },
    {
        "code": "VJ.4",
        "traduction": "Emprunts souscrits en cours d’exercice Montant plus de 5 ans"
    },
    {
        "code": "VK.1",
        "traduction": "Emprunts remboursés en cours d’exercice Montant brut"
    },
    {
        "code": "VK.2",
        "traduction": "Emprunts remboursés en cours d’exercice Montant 1 an au plus"
    },
    {
        "code": "VK.3",
        "traduction": "Emprunts remboursés en cours d’exercice Montant de 1 à 5 ans"
    },
    {
        "code": "VK.4",
        "traduction": "Emprunts remboursés en cours d’exercice Montant plus de 5 ans"
    },
    {
        "code": "AFF.1",
        "traduction": "Montant année N"
    },
    {
        "code": "AFF.2",
        "traduction": "Montant année N-1"
    },
    {
        "code": "AFF.3",
        "traduction": ""
    },
    {
        "code": "AFF.4",
        "traduction": ""
    },
    {
        "code": "ZE.1",
        "traduction": "Dividendes Montant année N"
    },
    {
        "code": "ZE.2",
        "traduction": "Dividendes Montant année N-1"
    },
    {
        "code": "YQ.1",
        "traduction": "Engagement de crédit-bail mobilier Montant année N"
    },
    {
        "code": "YQ.2",
        "traduction": "Engagement de crédit-bail mobilier Montant année N-1"
    },
    {
        "code": "YR.1",
        "traduction": "Engagement de crédit-bail immobilier Montant année N"
    },
    {
        "code": "YR.2",
        "traduction": "Engagement de crédit-bail immobilier Montant année N-1"
    },
    {
        "code": "YS.1",
        "traduction": "Effets portés à l’escompte et non échus Montant année N"
    },
    {
        "code": "YS.2",
        "traduction": "Effets portés à l’escompte et non échus Montant année N-1"
    },
    {
        "code": "YT.1",
        "traduction": "Sous-traitance Montant année N"
    },
    {
        "code": "YT.2",
        "traduction": "Sous-traitance Montant année N-1"
    },
    {
        "code": "XQ.1",
        "traduction": "Location, charges locatives et de copropriété Montant année N"
    },
    {
        "code": "XQ.2",
        "traduction": "Location, charges locatives et de copropriété Montant année N-1"
    },
    {
        "code": "YU.1",
        "traduction": "Personnel extérieur à l’entreprise Montant année N"
    },
    {
        "code": "YU.2",
        "traduction": "Personnel extérieur à l’entreprise Montant année N-1"
    },
    {
        "code": "SS.1",
        "traduction": "Rémunération d’intermédiaires et honoraires (hors rétrocessions) Montant année N"
    },
    {
        "code": "SS.2",
        "traduction": "Rémunération d’intermédiaires et honoraires (hors rétrocessions) Montant année N-1"
    },
    {
        "code": "YV.1",
        "traduction": "Rétrocessions d’honoraires, commissions et courtages Montant année N"
    },
    {
        "code": "YV.2",
        "traduction": "Rétrocessions d’honoraires, commissions et courtages Montant année N-1"
    },
    {
        "code": "ST.1",
        "traduction": "Autres comptes Montant année N"
    },
    {
        "code": "ST.2",
        "traduction": "Autres comptes Montant année N-1"
    },
    {
        "code": "ZJ.1",
        "traduction": "Total du poste correspondant à la ligne FW du tableau n° 2052 Montant année N"
    },
    {
        "code": "ZJ.2",
        "traduction": "Total du poste correspondant à la ligne FW du tableau n° 2052 Montant année N-1"
    },
    {
        "code": "YW.1",
        "traduction": "Taxe professionnelle Montant année N"
    },
    {
        "code": "YW.2",
        "traduction": "Taxe professionnelle Montant année N-1"
    },
    {
        "code": "9Z.1",
        "traduction": "Autres impôts, taxes et versements assimilés Montant année N"
    },
    {
        "code": "9Z.2",
        "traduction": "Autres impôts, taxes et versements assimilés Montant année N-1"
    },
    {
        "code": "YX.1",
        "traduction": "Total du compte correspondant à la ligne FX du tableau n° 2052 Montant année N"
    },
    {
        "code": "YX.2",
        "traduction": "Total du compte correspondant à la ligne FX du tableau n° 2052 Montant année N-1"
    },
    {
        "code": "YY.1",
        "traduction": "Montant de la TVA. collectée Montant année N"
    },
    {
        "code": "YY.2",
        "traduction": "Montant de la TVA. collectée Montant année N-1"
    },
    {
        "code": "YZ.1",
        "traduction": "Total TVA. déductible sur biens et services Montant année N"
    },
    {
        "code": "YZ.2",
        "traduction": "Total TVA. déductible sur biens et services Montant année N-1"
    },
    {
        "code": "YP.1",
        "traduction": "Effectif moyen du personnel Montant année N"
    },
    {
        "code": "YP.2",
        "traduction": "Effectif moyen du personnel Montant année N-1"
    }
];