
const CODE_ASSURANCE = "A";
const CODE_BANQUE = "B";
const CODE_SIMPLIFIE = "S";
const CODE_COMPLET = "C";
const CODE_CONSOLIDE = "K";

// export enum CodeAccountType {
//     TOUT = "Tout",
//     Assurance = "A",
//     Banque = "B",
//     Complet = "C",
//     Simple = "S",
//     Consolide = "K"
// }
export enum CodeAccountType {
    TOUT = "Tout",
    Assurance = "Assurance",
    Banque = "Banque",
    Complet = "Complet",
    Simple = "Simple",
    Consolide = "Consolidé"
}