import { ApiCustomQuery, CustomQuery } from "../models/CustomQuery";

import { csvA, csvB, csvC, csvS, csvK } from '../constants/canvasRoot';
import { Critere } from "../models/Critere";
import { CustomItem } from "../models/CustomItem";
import { CodeAccountType } from "../enums/AccountType";

import { listCritereString, listCritereStringObject } from "../data/string_attributs";
import { AutocompleteItem } from "../models/autocomplete";
import { listCriteresComplet } from "../data/complet_attributs";
import { listCriteresAssurance } from "../data/assurance_attributs";
import { listCriteresBanque } from "../data/banque_attributs";
import { listCriteresConsolide } from "../data/consolide_attributs";
import { listCriteresSimple } from "../data/simple_attributs";
import { QuerysFilters } from "../models/QuerysFilters";
import { Operators } from "../enums/Operator.enum";

export class Tools {

  // return true if string contain other string
  static compareString(text: string, text2: string): boolean {
    if(text.length && text2.length)
      return text.toUpperCase().includes(text2.toUpperCase());
    return false;
  }

  // return pos of query in list
  static getIndexQuery(critere: string, operator: string, querys: CustomQuery[]): number {
    const selectedIndex = querys.findIndex(query =>
      query.critereName.toLowerCase() === critere.toLowerCase() && query.operator.toLocaleLowerCase() === operator.toLowerCase()
    );
    //console.log("select index : " + selectedIndex);
    return selectedIndex;
  }

  static  getCSVFormat(canvas:any[], dataCSV: any): any[] {
    //console.log(canvas.length, ' canvas :>> ', canvas);
    var rows: any[] = [];
    const NB_LIGNE = canvas.length;
    //console.log(NB_LIGNE + 'canvas[1][0] :>> ', canvas[1][0]);
    let size = 0;
    // Partie identité
    if (canvas.length >= NB_LIGNE) {
      for (let i = 0; i < 17; i++) {
        rows.push(canvas[i]);
        rows[i][1] = dataCSV[canvas[i][0]];
      }
      // Partie à 6 à 4 column
      for (let i = 17; i < NB_LIGNE -1; i++) {
        rows.push(canvas[i]);
        //console.log("i : ",i, " ", canvas[i][2].length, ' canvas[i][2] :>> ', canvas[i][2] , " dataCSV[canvas[i][0] ", canvas[i]);
        if(!(canvas[i][2].length > 2)){
          for (let j = 2; j < size; j++) {
            rows[i][j] = dataCSV[canvas[i][0]] && dataCSV[canvas[i][0]][(j-1)];
          }
        }// On donne le nombre de colonne
        else {
          if(canvas[i][5].length > 2)
            size = 6;
          else
            size = 4;
        }
      }
    }
    //console.log('canvas :>> ', rows);
    return rows; 
  }

  // return all statement of csv
/*   static  formatCSV(dataCSV: any[]): any[] {
    var newData = [];

    for (let i = 0; i < dataCSV.length; i++) {
      let row: any[] = [];
      let rows: any[] = Object.values(dataCSV[i]);
      const words = rows[0].split(';');

      let j = 0;
      for (let index = 0; index < words.length; index++) {

        row.push(words[index]);

      }

      newData.push(row)

    }
    return newData;
  } */

  /*   {
      code: string,
      traduction: string
  } */
  // return all statement of csv
  /* V1 for d3 librairie
  static formatCSVCanvas(dataCSV: any): Critere[] {
    //console.log('dataCSV :>> ', dataCSV);
    var rows: Critere[] = [{
      code: "siren",
      traduction: "siren"
    }];
    const NB_LIGNE = 264;
    // Partie identité
    if (dataCSV.length >= NB_LIGNE) {
      for (let i = 0; i < 16; i++) {
        let row: any[] = Object.values(dataCSV[i]);
        const words = row[0].split(';');
        rows.push({
          code: words[0],
          traduction: words[1]
        });
      }
      // Partie à 4 column
      for (let i = 16; i < 246; i++) {
        let row: any[] = Object.values(dataCSV[i]);
        const words = row[0].split(';');
        for (let j = 2; j < 6; j++) {
          rows.push({
            code: (words[0] + "." +  (j - 1)),
            traduction: words[j] == undefined ? "vide" : words[j]
          });
        }
      }
      // Partie à 2 column
      for (let i = 246; i < 264; i++) {
        let row: any[] = Object.values(dataCSV[i]);
        const words = row[0].split(';');
        for (let j = 2; j < 4; j++) {
          rows.push({
            code: (words[0] + "." +  (j - 1)),
            traduction: words[j] == undefined ? "vide" : words[j]
          });
        }
      }
    }
    return rows;
  }
*/
/*
static formatCSVCanvasA_B(maxCol:number,maxLine:number,dataCSV: any): Critere[] {
  console.log(dataCSV.length, 'dataCSV :>> ', dataCSV);
  console.log('dataCSV[1][0] :>> ', dataCSV[1][0]);
  var rows: Critere[] = [];
  const NB_LIGNE = 27;
  // Partie identité
  if (dataCSV.length >= NB_LIGNE) {
    for (let i = 0; i < 17; i++) {
      let row: any[] = dataCSV[i];
      rows.push({
        code: row[0],
        traduction: row[1]
      });
    }
    // Partie à 4 column
    for (let i = 17; i < maxLine; i++) {
      let row: any[] = dataCSV[i];
      for (let j = 2; j < maxCol; j++) {
        rows.push({
          code: (row[0] + "." +  (j - 1)),
          traduction: row[j] == undefined ? "vide" : row[j]
        });
      }
    }
  }

  console.log('rows :>> ', rows);
  return rows;
}
static formatCSVCanvasC(dataCSV: any): Critere[] {
  console.log(dataCSV.length, 'dataCSV :>> ', dataCSV[1]);
  console.log('dataCSV[1][0] :>> ', dataCSV[1][0]);
  var rows: Critere[] = [];
  const NB_LIGNE = 264;
  // Partie identité
  if (dataCSV.length >= NB_LIGNE) {
    for (let i = 0; i < 17; i++) {
      let row: any[] = dataCSV[i];
      rows.push({
        code: row[0],
        traduction: row[1]
      });
    }
    // Partie à 4 column
    for (let i = 17; i < 247; i++) {
      let row: any[] = dataCSV[i];
      for (let j = 2; j < 6; j++) {
        rows.push({
          code: (row[0] + "." +  (j - 1)),
          traduction: row[j] == undefined ? "vide" : row[j]
        });
      }
    }
    // Partie à 2 column
    for (let i = 247; i < 265; i++) {
      let row: any[] = dataCSV[i];
      for (let j = 2; j < 4; j++) {
        rows.push({
          code: (row[0] + "." +  (j - 1)),
          traduction: row[j] == undefined ? "vide" : row[j]
        });
      }
    }
  }
  return rows;
}*/
/*
static formatCSVCanvasK(dataCSV: any): Critere[] {
  console.log(dataCSV.length, 'dataCSV :>> ', dataCSV);
  console.log('dataCSV[1][0] :>> ', dataCSV[1][0]);
  var rows: Critere[] = [];
  const NB_LIGNE = 170;
  // Partie identité
  if (dataCSV.length >= NB_LIGNE) {
    for (let i = 0; i < 17; i++) {
      let row: any[] = dataCSV[i];
      rows.push({
        code: row[0],
        traduction: row[1]
      });
    }
    // Partie à 4 column
    for (let i = 17; i < 62; i++) {
      let row: any[] = dataCSV[i];
      for (let j = 2; j < 6; j++) {
        rows.push({
          code: (row[0] + "." +  (j - 1)),
          traduction: row[j] == undefined ? "vide" : row[j]
        });
      }
    }

    for (let i = 62; i < 106; i++) {
      let row: any[] = dataCSV[i];
      for (let j = 2; j < 4; j++) {
        rows.push({
          code: (row[0] + "." +  (j - 1)),
          traduction: row[j] == undefined ? "vide" : row[j]
        });
      }
    }
    for (let i = 106; i < 148; i++) {
      let row: any[] = dataCSV[i];
      for (let j = 2; j < 6; j++) {
        rows.push({
          code: (row[0] + "." +  (j - 1)),
          traduction: row[j] == undefined ? "vide" : row[j]
        });
      }
    }
    for (let i = 148; i < 170; i++) {
      let row: any[] = dataCSV[i];
      for (let j = 2; j < 4; j++) {
        rows.push({
          code: (row[0] + "." +  (j - 1)),
          traduction: row[j] == undefined ? "vide" : row[j]
        });
      }
    }
  }

  console.log('rows :>> ', rows);
  return rows;
}
*/

  static formatCSVCanvasS(dataCSV: any): Critere[] {
    console.log(dataCSV.length, 'dataCSV :>> ', dataCSV);
    console.log('dataCSV[1][0] :>> ', dataCSV[1][0]);
    var rows: Critere[] = [];
    const NB_LIGNE = 143;
    // Partie identité
    if (dataCSV.length >= NB_LIGNE) {
      for (let i = 0; i < 17; i++) {
        let row: any[] = dataCSV[i];
        rows.push({
          code: row[0],
          traduction: row[1]
        });
      }
      // Partie à 4 column
      for (let i = 17; i < 34; i++) {
        let row: any[] = dataCSV[i];
        for (let j = 2; j < 6; j++) {
          rows.push({
            code: (row[0] + "." +  (j - 1)),
            traduction: row[j] == undefined ? "vide" : row[j]
          });
        }
      }

      for (let i = 34; i < 59; i++) {
        let row: any[] = dataCSV[i];
        for (let j = 5; j < 6; j++) {
          rows.push({
            code: (row[0] + "." +  (j - 1)),
            traduction: row[j] == undefined ? "vide" : row[j]
          });
        }
      }
      for (let i = 59; i < 98; i++) {
        let row: any[] = dataCSV[i];
        for (let j = 2; j < 4; j++) {
          rows.push({
            code: (row[0] + "." +  (j - 1)),
            traduction: row[j] == undefined ? "vide" : row[j]
          });
        }
      }
      for (let i = 98; i < 143; i++) {
        let row: any[] = dataCSV[i];
        for (let j = 2; j < 3; j++) {
          rows.push({
            code: (row[0] + "." +  (j - 1)),
            traduction: row[j] == undefined ? "vide" : row[j]
          });
        }
      }
    }

    console.log('rows :>> ', rows);
    return rows;
  }

  // return true if the list contains string parameter
  static listStringContainsCritere(code: string, listCritereString: string[]): boolean {
    if (code && listCritereString.find((cr: string) => this.compareString(cr, code))) {
      return true;
    }
    else {
      return false;
    }
  }


  // return critere list wich contains value
  static searchCritereInList(criteres: Critere[], value: string): Critere[] {
    
    return criteres.filter(cr => this.compareString(cr.code, value) ||
      this.compareString(cr.traduction, value));
  }

  // return string wich contains value
  static searchStringOfList(list: string[], value: string, size: number): string[] {

    return !value ? list.slice(0, size) : list.filter(text => this.compareString(text, value)).slice(0, size);

  }

  static searchElementInList(data: any, text: string = ' ', size: number): CustomItem[] {

    let list: CustomItem[] = [];
    //console.log("currentQuery.value : ",text);
    for (const [key, value] of Object.entries(data)) {
      const val: string = data[key];
      //console.log(`${key}: ${value}`);

      if (!text || this.compareString(val, text)) {
        list.push({ code: key, value: val });
      }
    }
    // return list.filter((element) => element.toUpperCase().includes(value.toUpperCase()));
    return list.slice(0, size);
  }

  static tranlateOperator(op: string): string {
    if (op == "less_than") {
      return "<";
    }
    else if (op === "equals_to") {
      return "=";
    }
    else {
      return ">";
    }
  }


  static getCSVRoot(type: string): string {
    var csv: string;

    switch (type) {
      case 'A':
        csv = csvA;
        break;
      case 'B':
        csv = csvB;
        break;
      case 'C':
        csv = csvC;
        break;
      case 'K':
        csv = csvK;
        break;
      case 'S':
        csv = csvS;
        break;
      default:
        csv = csvC;

    }
    return csv;
  }

  static getCritereList(type: CodeAccountType): Critere[] {
    if (type === CodeAccountType.Simple) {
      return [...listCriteresSimple];
    }
    else if (type === CodeAccountType.Consolide) {
      return listCriteresConsolide;
    }
    else if (type === CodeAccountType.Complet) {

      return listCriteresComplet;
    }
    else if (type === CodeAccountType.Assurance) {
      return listCriteresAssurance;
    }
    else if (type === CodeAccountType.Banque) {
      return listCriteresBanque;
    }

    return []
  }

  static getListFormatQueryText = (listQuery: CustomQuery[], maxSize: number): string[] => {

    let textList: string[] = [];
    let textLength = 0;
    let index: number = 0;
    while (textLength < maxSize && index < listQuery.length) {
      const currentQuery = listQuery[index];
      const text = currentQuery.critereName + " " + currentQuery.operator + " à " + currentQuery.value;
      textList.push(text);
      textLength += text.length;
      index++;
    }
    if(textLength >= maxSize){
      textList.push('...');
    }
    //console.log('textList :>> ', textList);
    return textList;
  }


  static getUrlSearchCompany = (apiCustomQuery: ApiCustomQuery): string => {
    const { accountType, listQuerys, page } = apiCustomQuery;
    let urlSearch = `/?accountType=${accountType}&&` + `page=${page}`;

    listQuerys.forEach((qry, index) => {
      urlSearch += `&&${index}code=${qry.code}&&${index}critereName=${qry.critereName}&&${index}operator=${qry.operator}&&${index}value=${qry.value}`;
    })

    //console.log('customQuery :>> ', apiCustomQuery);
    //console.log('urlSearch :>> ', urlSearch);
    return urlSearch;
  }


  // static compareCustomQuery(query1: CustomQuery, query2: CustomQuery): boolean {
  //   if (query1.code == query2.code) {
  //     return false;
  //   }
  //   if (query1.critereName == query2.critereName) {
  //     return false;
  //   }
  //   if (query1.operator == query2.operator) {
  //     return false;
  //   }
  //   if (query1.value == query2.value) {
  //     return false;
  //   }
  //   return true;
  // }

  
  static getCustomQueryOfParams(params: any): CustomQuery[] {
    let customQuery: CustomQuery[] = [];
    //console.log('params :>> ', params["0code"]);
    let index = 0;
    while(params.get(index+"code") && params.get(index+"critereName") && params.get(index+"operator") && params.get(index+"value")){
      customQuery.push({
        code: params.get(index+"code"),
        critereName: params.get(index+"critereName"),
        operator: params.get(index+"operator"),
        value: params.get(index+"value"),
      })
      index ++;
    }
    return customQuery;
  }

  static getCriteresList(type:CodeAccountType): Critere[]{
    let newCriteres: Critere[] = Tools.getCritereList(type);
    return listCritereStringObject.concat(newCriteres);
  }

 
  static formatItemCritereToItem(critere: Critere): AutocompleteItem {
    const autocompleteItem: AutocompleteItem = {
      code: critere.code, value: critere.traduction, data: critere,
    }
    return autocompleteItem;
  }

  static formatItemStringToItem(text: string): AutocompleteItem {
    const autocompleteItem: AutocompleteItem = {
      code: text, value: text, data: text,
    }
    return autocompleteItem;
  }

  static formatCustomItemToItem(item: CustomItem): AutocompleteItem {
    const autocompleteItem: AutocompleteItem = {
      code: item.code, value: item.value, data: item.code,
    }
    return autocompleteItem;
  }

  static isCustomList(code: string): boolean {
    if (code === "adresse") {
      return false;
    }
    else if (code === "code_activite") {
      return false;
    }
    return true;
  }

  // return true if query is similar
  static compareQuery(q1: CustomQuery, q2: CustomQuery): boolean {
    if (q1.code === q2.code && q1.critereName === q2.critereName &&
      q1.operator === q2.operator && q1.value === q2.value) {
      return true;
    }
    else
      return false;
  }

  static isInt(variable:string|null) {
    if (variable && !Number.isNaN(Number.parseInt(variable))) {
      return true
    }
    else {
      return false
    }
  }
  
  static getAccounType(accountType: string | null): CodeAccountType | undefined{
    if(accountType){
      return Object.values(CodeAccountType).find(ca => ca == accountType);
    }
    return undefined;
  }

  static isStringValue = (critere: string): boolean => {
    return (!critere || Tools.listStringContainsCritere(critere, listCritereString));
  }

  static initializeQuerysFilters = (listQuerys: CustomQuery[] = []): QuerysFilters => {
  
    const more_than: any = {};
    const equals_to: any = {};
    const less_than: any = {};
  
    for (var i = 0; i < listQuerys.length; i++) {
      //console.log(i+" query : "+JSON.stringify(i) + " list : "+JSON.stringify(listQuerys[i]));
  
      const value = !listCritereString.includes(listQuerys[i].code) ?
        parseInt(listQuerys[i].value) : listQuerys[i].value;
  
      if (listQuerys[i].operator === Operators.SUPERIEUR) {
        more_than[listQuerys[i].code] = value;
      }
      else if (listQuerys[i].operator === Operators.EGALE) {
        equals_to[listQuerys[i].code] = value;
      }
      else if (listQuerys[i].operator === Operators.INFERIEUR) {
        less_than[listQuerys[i].code] = value;
      }
  
    }
  
    //check if there is a same property in less_than and more_than
    //if so, add it to and object and remove it from less_than and more_than
    const keys_less_than = Object.keys(less_than)
    const keys_more_than = Object.keys(more_than)
  
    const and: any = {}
  
    keys_less_than.forEach((key) => {
      if (keys_more_than.includes(key)) {
        and[key] = {}
        and[key]["more_than"] = more_than[key]
        and[key]["less_than"] = less_than[key]
  
        delete more_than[key]
        delete less_than[key]
      }
    })
  
    const querysFilters  : QuerysFilters = {
      more_than,
      equals_to,
      less_than,
      and
    }
  
    return querysFilters;
  }
} 