import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useEffect, useState } from 'react';
import { Tools } from '../../screen/tools';
import Form from 'react-bootstrap/Form';
import { getUrlCSV } from '../../actions/companyActions';
import { CSVRequest } from '../../models/RequestCSV.model';
import { useDispatch } from 'react-redux';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { CritereSelect } from '../../models/Critere';
import { ApiCustomQuery } from '../../models/CustomQuery';
import { NB_RESULTS_MAX } from '../../constants/companyConstants';
import { QuerysFilters } from '../../models/QuerysFilters';
import AutoCompleteInputSelectComponent from '../AutocompleteInputSelect';
import ModalNotification from './Notification';
import { ModalTypes } from '../../enums/ModalTypes.eum';
import { ModalContext } from '../../ModalContext';
import React, { useContext } from 'react';

const resultSizeList = [NB_RESULTS_MAX,1000,10000,100000];

export const ModalDownloadCSV
: React.FC<{ apiQquery: ApiCustomQuery | undefined, hideModal: any }> = ({ apiQquery, hideModal }) => {
  
    const [critereList, setCritereList] = useState<CritereSelect[]>([]);
    const [urlCSV, setUrlCSV] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [allActive, setAllActive] = useState<boolean>(false);
    const [resultSize, setResultSize] = useState<number>(apiQquery?.limit || resultSizeList[0]);

    const dispatch = useDispatch();

    const sortBySubstring = (criteres: CritereSelect[], match: string): CritereSelect[] => {
        let newCritereList: CritereSelect[] = criteres.filter(cr => Tools.compareString(cr.traduction, match));
        return newCritereList.concat(criteres.filter(cr => !Tools.compareString(cr.traduction, match)));
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchText(value);
    }


    useEffect(() => {
        setCritereList(sortBySubstring(critereList, searchText));

    }, [searchText]);

    useEffect(() => {
        console.log('props :>> ', apiQquery);
        if (apiQquery?.accountType) {
            console.log("enter query");
            setCritereList(Tools.getCritereList(apiQquery.accountType).map((cr => {
                return { ...cr, selected: false }
            })));
        }

    }, [apiQquery])

    const selectCritere = (code: string) => {
        const newCritereList: CritereSelect[] = critereList.map(cr => {
            return { ...cr, selected: (cr.code == code) ? !cr.selected : cr.selected }
        });
        setCritereList(newCritereList);
    }

    const submit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (critereList.find(cr => cr.selected)) {
            getUrlDownload();
        }
        else {
            alert('fields can not be empty',)
        }
    }

    const getUrlDownload = () => {

        //console.log('api search :>> ', apiQquery);
        // est ce que fileds peut être vide ?

        if (critereList.find(cr => cr.selected)) {

            if (apiQquery?.accountType) {
                setLoading(true);
                //console.log('apiQquery :>> ', apiQquery);
                const querysFilters  : QuerysFilters = Tools.initializeQuerysFilters(apiQquery.listQuerys);

                if(Object.keys(querysFilters.more_than).length === 0){
                    delete querysFilters.more_than;
                }
                if(Object.keys(querysFilters.equals_to).length === 0){
                    delete querysFilters.equals_to;
                }
                if(Object.keys(querysFilters.less_than).length === 0){
                    delete querysFilters.less_than;
                }
                if(Object.keys(querysFilters.and).length === 0){
                    delete querysFilters.and;
                }

                const offset:number = ((apiQquery.page - 1) * NB_RESULTS_MAX);
                
                const request: CSVRequest = {
                    fields: critereList.filter(cr => cr.selected).map(cr => cr.code),
                    limit: resultSize,
                    offset,
                    ...querysFilters
                };

                console.log('request :>> ', request);


                getUrlCSV({ request })
                    .then((response: any) => response.text())
                    .then((response: any) => {
                        if(!response.error){
                            console.log("link : ", response)
                            setUrlCSV(response)
                            setLoading(false);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        alert(`ERROR: ${err}`)
                        
                    });
            }
        }
        else {
            alert('fields can not be empty',)
        }
    }

    const clickAllBox = ()  => {
        
        if(allActive){
            setCritereList(critereList.map(cr => { return {...cr, selected : false} }))
        }
        else{
            setCritereList(critereList.map(cr => { return {...cr, selected : true} }))
        }

        setAllActive(!allActive);
    }

    const downloadCSV = () => {
        if (urlCSV) {
            //console.log('urlCSV :>> ', urlCSV);
            window.open(urlCSV)
            hideModal();
            setUrlCSV('');
        }
        else {
            alert("Aucun lien trouvé")
        }
    }

    return (
            <Modal show={true} onHide={() => hideModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Téléchargement </Modal.Title>
                </Modal.Header>

                <Modal.Body >

                <form onSubmit={submit} className="col-12 p-3">

                        <div className='col-12 row mb-4 '>
                            <input value={searchText} onChange={handleChange} className='form-control col-7 ' placeholder='name...' />
                            <AutoCompleteInputSelectComponent
                                propsInput={{
                                    value: resultSize,
                                    name: "nbValue",
                                    
                                }}
                                propsList={{
                                    show: true,
                                    tabIndex: 2,
                                    items: resultSizeList.map((at: number) => {
                                        return { code: String(at), value: String(at), data: at }
                                    }),
                                    selectItem: (at: any) => setResultSize(at)
                                }}
                                />

                            <div className='text-center col-1'>
                                <button className="custom-icon" onClick={() => setSearchText('')}>
                                    <AiOutlineCloseCircle size={30} />
                                </button>
                            </div>
                        </div>
                        <div className='col-12 border-bottom text-left'>
                            <input type="checkbox" className='col-1' id="allCritereList" name="allCritereList"
                                onChange={clickAllBox} checked={allActive}/>
                            <label >&nbsp; Tout</label>
                        </div>
                        <div className='scroll-container' >
                        {critereList.map((cr, i) =>
                            <div key={`checkbox${cr.code}`} 
                            className={"mb-3 p-1 border rounded " + (searchText && Tools.compareString(cr.traduction, searchText) && 'bg-success p-1 text-light')} 
                            onClick={() => selectCritere(cr.code)}
                            >
                             <input type="checkbox" className='col-1' id="allCritereList" name="allCritereList"
                                onChange={() => selectCritere(cr.code)} checked={critereList[i].selected || false}
                                />
                            <label >&nbsp; {`${cr.traduction}`}</label>
                                {/* <Form.Check
                                    type={"checkbox"}
                                    id={"checkbox"}
                                    label={`${cr.traduction}`}
                                    onChange={() => selectCritere(cr.code)}
                                    checked={critereList[i].selected || false}
                                    
                                /> */}
                            </div>
                        )}
                        </div>

                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => getUrlDownload()}>
                        Valider
                    </Button>
                    {loading ? "Lien en cours..." : urlCSV &&
                        <Button variant="success" onClick={() => downloadCSV()}>
                            Telecharger
                        </Button>
                    }
                </Modal.Footer>

            </Modal>
    );
}
